import { ReactComponent as SkillsIcon } from './skills-mobile.svg';
import { ReactComponent as SellIcon } from './sell.svg';
import { ReactComponent as MissionIcon } from './mission.svg';

export default {
	title: 'Browse Categories',
	footerButton: {
		title: 'All Services',
		link: 'https://www.hostjane.com/marketplace/categories',
		internal: false,
	},
	primaryLinks: [
		{
		},
		{
		},
		{
		},
	],
	navs: [
		{
			title: 'Web / Mobile / Tech',
			navs: [
				{
					title: 'View All',
					strong: true,
					link:
						'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
					internal: false,
				},
				{
					title: 'Web Development',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
							internal: false,
						},
						{
							title: 'Full Stack Developers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/full-stack-developers',
							internal: false,
						},
						{
							title: 'Frontend Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/frontend-development',
							internal: false,
						},
						{
							title: 'Backend Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/backend-development',
							internal: false,
						},
						{
							title: 'PHP Framework',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/php-framework',
							internal: false,
						},
						{
							title: 'HTML / CSS',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/html-css',
							internal: false,
						},
						{
							title: 'Content Management System (CMS)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/content-management-system-cms',
							internal: false,
						},
						{
							title: 'UX / UI / Responsive',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/ux-ui-responsive',
							internal: false,
						},
						{
							title: 'ECommerce',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/ecommerce',
							internal: false,
						},
						{
							title: 'Web Servers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/web-servers',
							internal: false,
						},
						{
							title: 'Refactoring / Rewriting Code',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/refactoring-rewriting-code',
							internal: false,
						},
						{
							title: 'Templates / Wireframes / Mockups',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/templates-wireframes-mockups',
							internal: false,
						},
						{
							title: 'Social Media / Networks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/social-media-networks',
							internal: false,
						},
						{
							title: 'Text Search Engines',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/text-search-engines',
							internal: false,
						},
						{
							title: 'Domains / Hosting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/domains-hosting',
							internal: false,
						},
						{
							title: 'Maintenance / Security',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/maintenance-security',
							internal: false,
						},
						{
							title: 'Graphics / Interactive',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/graphics-interactive',
							internal: false,
						},
						{
							title: 'Email / Chat',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/email-chat',
							internal: false,
						},
						{
							title: 'Jamstack',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development/jamstack',
							internal: false,
						},
					],
				},
				{
					title: 'Web Design / Apps',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps',
							internal: false,
						},
						{
							title: 'Web Page Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/web-page-design',
							internal: false,
						},
						{
							title: 'Website Redesign',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/website-redesign',
							internal: false,
						},
						{
							title: 'WordPress Theme Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/wordpress-theme-design',
							internal: false,
						},
						{
							title: 'iOS App Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/ios-app-design',
							internal: false,
						},
						{
							title: 'Landing Page Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/landing-page-design',
							internal: false,
						},
						{
							title: 'Android App Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/android-app-design',
							internal: false,
						},
						{
							title: 'Custom App Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/custom-app-design',
							internal: false,
						},
						{
							title: 'Blog Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/blog-design',
							internal: false,
						},
						{
							title: 'Social Media Page Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/social-media-page-design',
							internal: false,
						},
						{
							title: 'Icons / Buttons Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/icons-buttons-design',
							internal: false,
						},
						{
							title: 'Website Icon Set Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/website-icon-set-design',
							internal: false,
						},
						{
							title: 'App Icon Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/app-icon-design',
							internal: false,
						},
						{
							title: 'Form Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/form-design',
							internal: false,
						},
						{
							title: 'Twitter Header Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/twitter-header-design',
							internal: false,
						},
						{
							title: 'YouTube Channel Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/youtube-channel-design',
							internal: false,
						},
						{
							title: 'Facebook / Meta Developers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/facebook-developers',
							internal: false,
						},
						{
							title: 'Banner Ad Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/banner-ad-design',
							internal: false,
						},
						{
							title: 'Other Website / App design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps/other-website-app-design',
							internal: false,
						},
					],
				},
				{
					title: 'WordPress',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress',
							internal: false,
						},
						{
							title: 'Full Website Creation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/full-website-creation',
							internal: false,
						},
						{
							title: 'Landing Page',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/landing-page',
							internal: false,
						},
						{
							title: 'Theme Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/theme-development',
							internal: false,
						},
						{
							title: 'Plugin Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/plugin-development',
							internal: false,
						},
						{
							title: 'Backup / Cloning / Migration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/backup-cloning-migration',
							internal: false,
						},
						{
							title: 'Customization',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/customization',
							internal: false,
						},
						{
							title: 'Bug Fixes',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress/bug-fixes',
							internal: false,
						},
					],
				},
				{
					title: 'Blockchain / Crypto / NFTs',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts',
							internal: false,
						},
						{
							title: 'Crypto Coins / Tokens',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/crypto-coins-tokens',
							internal: false,
						},
						{
							title: 'Non-Fungible Tokens (NFTs)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/non-fungible-tokens-nfts',
							internal: false,
						},
						{
							title: 'Digital Wallets',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/digital-wallet-e-wallet',
							internal: false,
						},
						{
							title: 'Cryptocurrency Exchanges',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/cryptocurrency-exchanges',
							internal: false,
						},
						{
							title: 'Decentralized Apps (dApps)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/decentralized-apps-dapps',
							internal: false,
						},
						{
							title: 'Crypto Payments',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/crypto-payments',
							internal: false,
						},
						{
							title: 'Smart Contracts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts/smart-contracts',
							internal: false,
						},
					],
				},
				{
					title: 'Apps / Mobile',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile',
							internal: false,
						},
						{
							title: 'Custom Apps',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/custom-apps',
							internal: false,
						},
						{
							title: 'Convert Site to App',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/convert-site-to-app',
							internal: false,
						},
						{
							title: 'Apps / Mobile Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/app-mobile-marketing',
							internal: false,
						},
						{
							title: 'iOS app development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/ios-app-development',
							internal: false,
						},
						{
							title: 'Android app development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/android-app-development',
							internal: false,
						},
						{
							title: 'Apps / Mobile Programming Languages',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/apps-mobile-programming-languages',
							internal: false,
						},
						{
							title: 'Responsive / Hybrid',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/responsive-hybrid',
							internal: false,
						},
						{
							title: 'Games',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile/games',
							internal: false,
						},
					],
				},
				{
					title: 'Math / Science Help',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms',
							internal: false,
						},
						{
							title: 'Math / Statistics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/math-statistics',
							internal: false,
						},
						{
							title: 'MATLAB',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/matlab',
							internal: false,
						},
						{
							title: 'Statistical Analysis',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/statistical-analysis',
							internal: false,
						},
						{
							title: 'Mathcad',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/mathcad',
							internal: false,
						},
						{
							title: 'Simulation Modeling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/simulation-modeling',
							internal: false,
						},
						{
							title: 'Regression Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/regression-testing',
							internal: false,
						},
						{
							title: 'Machine Learning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/machine-learning',
							internal: false,
						},
						{
							title: 'Business Intelligence / Analysis',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/business-intelligence-analysis',
							internal: false,
						},
						{
							title: 'Digital Signal Processing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/digital-signal-processing',
							internal: false,
						},
						{
							title: 'Google',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/google',
							internal: false,
						},
						{
							title: 'SCADA',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/scada',
							internal: false,
						},
						{
							title: 'Data Visualization',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/data-visualization',
							internal: false,
						},
						{
							title: 'Web Analytics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/web-analytics',
							internal: false,
						},
						{
							title: 'General Analytics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/analytics',
							internal: false,
						},
						{
							title: 'Power BI',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/power-bi',
							internal: false,
						},
						{
							title: 'Fortran',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/fortran',
							internal: false,
						},
						{
							title: 'Tableau',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/tableau',
							internal: false,
						},
						{
							title: 'Artifical Intelligence',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/artificial-intelligence',
							internal: false,
						},
						{
							title: 'Internet of Things (IOT)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/internet-of-things-iot',
							internal: false,
						},
						{
							title: 'IBM Resources',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/ibm',
							internal: false,
						},
						{
							title: 'SAS',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms/sas',
							internal: false,
						},
					],
				},
				{
					title: 'Programming / Software',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software',
							internal: false,
						},
						{
							title: 'Python',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/python',
							internal: false,
						},
						{
							title: 'JavaScript',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/javascript',
							internal: false,
						},
						{
							title: 'Java',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/java',
							internal: false,
						},
						{
							title: 'C#',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/c-sharp',
							internal: false,
						},
						{
							title: 'C / C++',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/c-plus-plus',
							internal: false,
						},
						{
							title: 'PHP',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/php',
							internal: false,
						},
						{
							title: 'HTML / XHTML',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/html-xhtml',
							internal: false,
						},
						{
							title: 'CSS',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/css',
							internal: false,
						},
						{
							title: 'Other Programming',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/other-programming',
							internal: false,
						},
						{
							title: 'Go',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/go',
							internal: false,
						},
						{
							title: 'Perl',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/perl',
							internal: false,
						},
						{
							title: 'SQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/sql',
							internal: false,
						},
						{
							title: 'Ruby / Ruby on Rails',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/ruby-ruby-on-rails',
							internal: false,
						},
						{
							title: '.NET development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/net-development',
							internal: false,
						},
						{
							title: 'React',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/react',
							internal: false,
						},
						{
							title: 'GIS / Geolocation / Maps',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/gis-geolocation-maps',
							internal: false,
						},
						{
							title: 'R Programming',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/r-programming',
							internal: false,
						},
						{
							title: 'React Native',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/react-native',
							internal: false,
						},
						{
							title: 'API',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/api',
							internal: false,
						},
						{
							title: 'Email Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/email-support',
							internal: false,
						},
						{
							title: 'IT Service Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/it-service-management',
							internal: false,
						},
						{
							title: 'Bootstrap',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/bootstrap',
							internal: false,
						},
						{
							title: 'Apple',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/apple',
							internal: false,
						},
						{
							title: 'Angular',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/angular',
							internal: false,
						},
						{
							title: 'Vue.js',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/vuejs',
							internal: false,
						},
						{
							title: 'Swift',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/swift',
							internal: false,
						},
						{
							title: 'Kotlin',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/kotlin',
							internal: false,
						},
						{
							title: 'Object-Oriented',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/object-oriented',
							internal: false,
						},
						{
							title: 'Typescript',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/typescript',
							internal: false,
						},
						{
							title: 'Data Extraction / ETL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/data-extraction-etl',
							internal: false,
						},
						{
							title: 'Web Scraping',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/web-scraping',
							internal: false,
						},
						{
							title: 'Laravel',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/laravel',
							internal: false,
						},
						{
							title: 'Symfony',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/symfony',
							internal: false,
						},
						{
							title: 'CodeIgniter',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/codeigniter',
							internal: false,
						},
						{
							title: 'Scala',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/scala',
							internal: false,
						},
						{
							title: 'Graphics / Virtual Reality / AI',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/graphics-virtual-reality-ai',
							internal: false,
						},
						{
							title: 'Rust',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/rust',
							internal: false,
						},
						{
							title: 'Objective-C',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/objective-c',
							internal: false,
						},
						{
							title: 'Microsoft',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/microsoft',
							internal: false,
						},
						{
							title: 'NoSQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/NoSQL',
							internal: false,
						},
						{
							title: 'Unix / Linux',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/unix-linux',
							internal: false,
						},
						{
							title: 'Embedded Systems',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/embedded-systems',
							internal: false,
						},
						{
							title: 'XML',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/xml',
							internal: false,
						},
						{
							title: 'Open Source',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/open-source',
							internal: false,
						},
						{
							title: 'JSON',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/json',
							internal: false,
						},
						{
							title: 'Version Control',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/version-control',
							internal: false,
						},
						{
							title: 'Cloud Computing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/cloud-computing',
							internal: false,
						},
						{
							title: 'Billing / Credit Cards / Finance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software/billing-credit-cards-finance',
							internal: false,
						},
					],
				},
				{
					title: 'Bug Fixing Services',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services',
							internal: false,
						},
						{
							title: 'Scalability issues',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/scalability-issues',
							internal: false,
						},
						{
							title: 'Logical / Functional Bugs:',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/logical-functional-bugs',
							internal: false,
						},
						{
							title: 'PHP Errors / Warnings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/php-errors-warnings',
							internal: false,
						},
						{
							title: 'Database Bugs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/database-bugs',
							internal: false,
						},
						{
							title: 'GUI Related Bugs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/gui-related-bugs',
							internal: false,
						},
						{
							title: 'Mobile Software Bugs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/mobile-software-bugs',
							internal: false,
						},
						{
							title: 'Cross Browser Compatibility',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/cross-browser-compatibility',
							internal: false,
						},
						{
							title: 'Run Time Errors',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/run-time-errors',
							internal: false,
						},
						{
							title: 'System Related Bugs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/system-related-bugs',
							internal: false,
						},
						{
							title: 'Version Regression',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/version-regression',
							internal: false,
						},
						{
							title: 'Memory Leaks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/memory-leaks',
							internal: false,
						},
						{
							title: 'Missing Commands',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/missing-commands',
							internal: false,
						},
						{
							title: 'Application Crash',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/application-crash',
							internal: false,
						},
						{
							title: 'API Bugs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/api-bugs',
							internal: false,
						},
						{
							title: 'Compilation Errors',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/compilation-errors',
							internal: false,
						},
						{
							title: 'Control Flow Bugs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/control-flow-bugs',
							internal: false,
						},
						{
							title: 'Other Bugs / Errors',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services/other-bugs-errors',
							internal: false,
						},
					],
				},
				{
					title: 'Game Development',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development',
							internal: false,
						},
						{
							title: 'Gaming',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/gaming',
							internal: false,
						},
						{
							title: 'Game Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/game-design',
							internal: false,
						},
						{
							title: '2D Games',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/2d-games',
							internal: false,
						},
						{
							title: '3D Games',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/3d-games',
							internal: false,
						},
						{
							title: 'Level Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/level-design',
							internal: false,
						},
						{
							title: 'Game Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/game-testing',
							internal: false,
						},
						{
							title: 'Social Games',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/social-games',
							internal: false,
						},
						{
							title: 'Mobile Game Dev',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/mobile-game-development',
							internal: false,
						},
						{
							title: 'Xbox Game Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/xbox-game-development',
							internal: false,
						},
						{
							title: 'Blockchain Gaming',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/blockchain-gaming',
							internal: false,
						},
						{
							title: 'Android Game Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/android-game-development',
							internal: false,
						},
						{
							title: 'Augmented Reality (AR)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/augmented-reality-ar',
							internal: false,
						},
						{
							title: 'Microsoft DirectX',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/microsoft-directx',
							internal: false,
						},
						{
							title: 'Cocos2D',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/cocos2d',
							internal: false,
						},
						{
							title: 'Microsoft Kinect',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/microsoft-kinect',
							internal: false,
						},
						{
							title: 'Box2D',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/box2d',
							internal: false,
						},
						{
							title: 'Unreal Engine',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/unreal-engine',
							internal: false,
						},
						{
							title: 'Multiplayer',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/multiplayer',
							internal: false,
						},
						{
							title: 'Video Games',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/video-games',
							internal: false,
						},
						{
							title: 'Virtual Reality (VR)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development/virtual-reality-vr',
							internal: false,
						},
					],
				},
				{
					title: 'Ideas / Help / Consultation',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation',
							internal: false,
						},
						{
							title: 'Help Desk',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/help-desk',
							internal: false,
						},
						{
							title: 'Help / Planning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/help-planning',
							internal: false,
						},
						{
							title: 'Crypto / Blockchain Help',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/crypto-blockchain-help',
							internal: false,
						},
						{
							title: 'Concept Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/concept-development',
							internal: false,
						},
						{
							title: 'Information Architecture',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/information-architecture',
							internal: false,
						},
						{
							title: 'Information Systems',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/information-system',
							internal: false,
						},
						{
							title: 'Design Documents',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/design-documents',
							internal: false,
						},
						{
							title: 'Road Mapping',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/road-mapping',
							internal: false,
						},
						{
							title: 'Microsoft Visio',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/microsoft-visio',
							internal: false,
						},
						{
							title: 'Software Documentation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/software-documentation',
							internal: false,
						},
						{
							title: 'Functional Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation/functional-design',
							internal: false,
						},
					],
				},
				{
					title: 'Database Design / Admin',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration',
							internal: false,
						},
						{
							title: 'MySQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/mysql',
							internal: false,
						},
						{
							title: 'PostgreSQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/postgresql',
							internal: false,
						},
						{
							title: 'Database Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/database-design',
							internal: false,
						},
						{
							title: 'Oracle Databases',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/oracle',
							internal: false,
						},
						{
							title: 'SQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/sql',
							internal: false,
						},
						{
							title: 'Database Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/database-development',
							internal: false,
						},
						{
							title: 'Stored Procedures',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/stored-procedures',
							internal: false,
						},
						{
							title: 'Database Administration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/database-administration',
							internal: false,
						},
						{
							title: 'Query Optimization',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/query-optimization',
							internal: false,
						},
						{
							title: 'MongoDB',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/mongodb',
							internal: false,
						},
						{
							title: 'Microsoft SQL Server',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/microsoft-sql-server',
							internal: false,
						},
						{
							title: 'IBM Db2',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/ibm-db2',
							internal: false,
						},
						{
							title: 'PL/SQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/plsql',
							internal: false,
						},
						{
							title: 'Microsoft Access',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/microsoft-access',
							internal: false,
						},
						{
							title: 'Data Warehouse',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/data-warehouse',
							internal: false,
						},
						{
							title: 'Transact SQL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/transact-sql',
							internal: false,
						},
						{
							title: 'SQLite',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration/sqlite',
							internal: false,
						},
					],
				},
				{
					title: 'QA / Testing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing',
							internal: false,
						},
						{
							title: 'Troubleshooting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/troubleshooting',
							internal: false,
						},
						{
							title: 'QA / Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/quality-assurance-qa-and-testing',
							internal: false,
						},
						{
							title: 'Bug Fixes',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/bug-fixes',
							internal: false,
						},
						{
							title: 'W3C Validation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/w3c-validation',
							internal: false,
						},
						{
							title: 'Manual Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/manual-testing',
							internal: false,
						},
						{
							title: 'Performance Tuning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/performance-tuning',
							internal: false,
						},
						{
							title: 'Test Case Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/test-case-design',
							internal: false,
						},
						{
							title: 'Localization Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/localization-testing',
							internal: false,
						},
						{
							title: 'Functional Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/functional-testing',
							internal: false,
						},
						{
							title: 'Usability Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/usability-testing',
							internal: false,
						},
						{
							title: 'Regression Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/regression-testing',
							internal: false,
						},
						{
							title: 'Selenium',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/selenium',
							internal: false,
						},
						{
							title: 'Performance Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/performance-testing',
							internal: false,
						},
						{
							title: 'Unit Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/unit-testing',
							internal: false,
						},
						{
							title: 'Load Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/load-testing',
							internal: false,
						},
						{
							title: 'Validation Engineering',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/validation-engineering',
							internal: false,
						},
						{
							title: 'Test Driven Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/test-driven-development',
							internal: false,
						},
						{
							title: 'Automation Software Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/automation-software-testing',
							internal: false,
						},
						{
							title: 'JUnit',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/junit',
							internal: false,
						},
						{
							title: 'SoapUI',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing/soapui',
							internal: false,
						},
					],
				},
				{
					title: 'Networking / System Admin',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin',
							internal: false,
						},
						{
							title: 'AWS',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/aws',
							internal: false,
						},
						{
							title: 'Cloud Computing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/cloud-computing',
							internal: false,
						},
						{
							title: 'Blockchain Technology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/blockchain-technology',
							internal: false,
						},
						{
							title: 'Email / Chat / Video',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/email-chat-video',
							internal: false,
						},
						{
							title: 'Linux',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/linux',
							internal: false,
						},
						{
							title: 'Google Cloud',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/google-cloud',
							internal: false,
						},
						{
							title: 'Virtualization',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/virtualization',
							internal: false,
						},
						{
							title: 'Microsoft',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/microsoft',
							internal: false,
						},
						{
							title: 'Cisco',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/cisco',
							internal: false,
						},
						{
							title: 'Switches / Routers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/switches-routers',
							internal: false,
						},
						
						{
							title: 'Hardware',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/hardware',
							internal: false,
						},
						{
							title: 'General / Other Networking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/general-other-networking',
							internal: false,
						},
						{
							title: 'Other Servers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin/other-servers',
							internal: false,
						},
					],
				},
				
				{
					title: 'Cybersecurity / InfoSec',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security',
							internal: false,
						},
						{
							title: 'Firewalls',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/firewalls',
							internal: false,
						},
						{
							title: 'Cryptocurrency',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/cryptocurrency',
							internal: false,
						},
						{
							title: 'Virus Removal',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/virus-removal',
							internal: false,
						},
						{
							title: 'SSL',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/ssl',
							internal: false,
						},
						{
							title: 'Mobile Security',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/mobile-security',
							internal: false,
						},
						{
							title: 'Online Payments Security',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/online-payments',
							internal: false,
						},
						{
							title: 'Checkpoint',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/checkpoint',
							internal: false,
						},
						{
							title: 'Data Loss / Recovery',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/data-loss-recovery',
							internal: false,
						},
						{
							title: 'Data Security',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/data-security',
							internal: false,
						},
						{
							title: 'Malware',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/malware',
							internal: false,
						},
						{
							title: 'iptables',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/iptables',
							internal: false,
						},
						{
							title: 'Encryption Software',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/encryption-software',
							internal: false,
						},
						{
							title: 'Penetration Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/penetration-testing',
							internal: false,
						},
						{
							title: 'Security Testing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/security-testing',
							internal: false,
						},
						{
							title: 'Ethical Hacking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/ethical-hacking',
							internal: false,
						},
						{
							title: 'Antivirus / Antispam',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security/antivirus-antispam',
							internal: false,
						},
					],
				},
				{
					title: 'AI Services',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services',
							internal: false,
						},
						{
							title: 'AI Mobile Apps',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-mobile-apps',
							internal: false,
						},
						{
							title: 'AI Programming',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-programming',
							internal: false,
						},
						{
							title: 'AI Websites & Software',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-websites-software',
							internal: false,
						},
						{
							title: 'AI Chatbots',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-chatbots',
							internal: false,
						},
						{
							title: 'AI for Business',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-for-business',
							internal: false,
						},
						{
							title: 'AI Content',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-content',
							internal: false,
						},
						{
							title: 'Data Science + ML',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/data-science-machine-learning',
							internal: false,
						},
						{
							title: 'AI Fine-Tuning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-fine-tuning',
							internal: false,
						},
						{
							title: 'AI Integrations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-integrations',
							internal: false,
						},
						{
							title: 'AI Data Analytics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-data-analytics',
							internal: false,
						},
						{
							title: 'AI Data Visualization',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ai-services/ai-data-visualization',
							internal: false,
						},
					],
				},
				{
					title: 'ERP / CRM / SCM',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm',
							internal: false,
						},
						{
							title: 'Salesforce',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/salesforce',
							internal: false,
						},
						{
							title: 'Visualforce',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/visualforce',
							internal: false,
						},
						{
							title: 'Microsoft Dynamics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/microsoft-dynamics',
							internal: false,
						},
						{
							title: 'Zendesk',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/zendesk',
							internal: false,
						},
						{
							title: 'Oracle Siebel CRM',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/oracle-siebel-crm',
							internal: false,
						},
						{
							title: 'Oracle JD Edwards',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/oracle-jd-edwards',
							internal: false,
						},
						{
							title: 'Oracle Hyperion',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/oracle-hyperion',
							internal: false,
						},
						{
							title: 'SAP ABAP',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/sap-abap',
							internal: false,
						},
						{
							title: 'Zoho CRM',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/zoho-crm',
							internal: false,
						},
						{
							title: 'Zoho',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/zoho',
							internal: false,
						},
						{
							title: 'Zoho Creator',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/zoho-creator',
							internal: false,
						},
						{
							title: 'Bitrix24',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/bitrix24',
							internal: false,
						},
						{
							title: 'Keap (Infusionsoft)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/infusionsoft-keap',
							internal: false,
						},
						{
							title: 'Odoo',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/odoo',
							internal: false,
						},
						{
							title: 'SugarCRM',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm/sugarcrm',
							internal: false,
						},
					],
				},
				{
					title: 'Telephony / Telecommunications',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications',
							internal: false,
						},
						{
							title: 'VoIP',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/voip',
							internal: false,
						},
						{
							title: 'Telephony',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/telephony',
							internal: false,
						},
						{
							title: 'Telecommunications Systems',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/telecommunications-systems',
							internal: false,
						},
						{
							title: 'Cisco VoIP',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/cisco-voip',
							internal: false,
						},
						{
							title: 'Cisco Unified Call Manager',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/cisco-unified-call-manager',
							internal: false,
						},
						{
							title: 'SMS Gateway',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/sms-gateway',
							internal: false,
						},
						{
							title: 'Code-Division Multiple Access',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/code-division-multiple-access',
							internal: false,
						},
						{
							title: 'Interactive Voice Response',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/interactive-voice-response',
							internal: false,
						},
						{
							title: 'OpenSIPS',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/opensips',
							internal: false,
						},
						{
							title: 'VoiceXML',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/voicexml',
							internal: false,
						},
						{
							title: 'Structured Cabling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/structured-cabling',
							internal: false,
						},
						{
							title: 'VICIdial',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/vicidial',
							internal: false,
						},
						{
							title: 'Qualcomm',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/qualcomm',
							internal: false,
						},
						{
							title: 'Asterisk',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/asterisk',
							internal: false,
						},
						{
							title: 'FreeSWITCH',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/freeswitch',
							internal: false,
						},
						{
							title: '3CX (Elastix)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/elastix',
							internal: false,
						},
						{
							title: 'Avaya',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications/avaya',
							internal: false,
						},
					],
				},
				{
					title: 'SAP',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap',
							internal: false,
						},
						{
							title: 'SAP HANA',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-hana',
							internal: false,
						},
						{
							title: 'SAP ERP',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-erp',
							internal: false,
						},
						{
							title: 'SAP CRM',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-crm',
							internal: false,
						},
						{
							title: 'SAP Basis',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-basis',
							internal: false,
						},
						{
							title: 'SAP Crystal Reports',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/crystal-reports',
							internal: false,
						},
						{
							title: 'SAP SD',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-sd',
							internal: false,
						},
						{
							title: 'SAP BW',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-bw',
							internal: false,
						},
						{
							title: 'SAP Business Intelligence (BI)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-bi',
							internal: false,
						},
						{
							title: 'SAP MM',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-mm',
							internal: false,
						},
						{
							title: 'SAP NetWeaver',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-netweaver',
							internal: false,
						},
						{
							title: 'SAP Business One',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-business-one',
							internal: false,
						},
						{
							title: 'SAP Fiori',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-fiori',
							internal: false,
						},
						{
							title: 'SAP FICO',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-fico',
							internal: false,
						},
						{
							title: 'SAP HR',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-hr',
							internal: false,
						},
						{
							title: 'SAP Scripts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-scripting',
							internal: false,
						},
						{
							title: 'SAP Commerce',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap/sap-commerce',
							internal: false,
						},
					],
				},
			],
		},
		{
			title: 'Design / Art / Video / Audio',
			navs: [
				{
					title: 'View All',
					strong: true,
					link:
						'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
					internal: false,
				},
				{
					title: 'Graphic Design / Logos',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos',
							internal: false,
						},
						{
							title: 'Logo Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/logo-design',
							internal: false,
						},
						{
							title: 'Business Identity / Branding',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/logos-corporate-id-branding',
							internal: false,
						},
						{
							title: 'Templates / Wireframes / Mockups',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/templates-wireframes-mockups',
							internal: false,
						},
						{
							title: 'Poster Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/poster-design',
							internal: false,
						},
						{
							title: 'Adobe Software',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/adobe-software',
							internal: false,
						},
						{
							title: 'Layout Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/layout-design',
							internal: false,
						},
						{
							title: 'Characters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/characters',
							internal: false,
						},
						{
							title: '2D Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/2d-design',
							internal: false,
						},
						{
							title: '3D Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/3d-design',
							internal: false,
						},
						{
							title: '3D Modeling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/3d-modeling',
							internal: false,
						},
						{
							title: 'Backgrounds',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/backgrounds',
							internal: false,
						},
						{
							title: 'Banner Ads',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/banner-ads',
							internal: false,
						},
						{
							title: 'Industrial Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/industrial',
							internal: false,
						},
						{
							title: 'Brochure Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/brochure-design',
							internal: false,
						},
						{
							title: 'Font Designing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/font-designing',
							internal: false,
						},
						{
							title: 'Colors / Typography / Textures',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/colors-typography-textures',
							internal: false,
						},
						{
							title: 'Presentations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/presentations',
							internal: false,
						},
						{
							title: 'Digital Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/digital',
							internal: false,
						},
						{
							title: 'Web / Email / Apps',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/web-email-apps',
							internal: false,
						},
						{
							title: 'Cover Art / Page Art / Layouts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/cover-art-page-art-layouts',
							internal: false,
						},
						{
							title: 'Advertisements',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/advertisements',
							internal: false,
						},
						{
							title: 'Other Graphic Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos/other-graphic-design',
							internal: false,
						},
					],
				},
				{
					title: 'Freelance Artists',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art',
							internal: false,
						},
						{
							title: 'Freelance Artists',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/freelance-artists',
							internal: false,
						},
						{
							title: 'Mascot Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/mascot-design',
							internal: false,
						},
						{
							title: 'Creative Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/creative-design',
							internal: false,
						},
						{
							title: 'Minimalist Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/minimalist-design',
							internal: false,
						},
						{
							title: 'Fine Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/fine-art',
							internal: false,
						},
						{
							title: 'Portraits',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/portraits',
							internal: false,
						},
						{
							title: 'Abstract Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/abstract-art',
							internal: false,
						},
						{
							title: 'Sequential Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/sequential-art',
							internal: false,
						},
						{
							title: 'Paint Tool SAI',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/paint-tool-sai',
							internal: false,
						},
						{
							title: 'Traditional Media',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/traditional-media',
							internal: false,
						},
						{
							title: 'Contemporary Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art/contemporary-art',
							internal: false,
						},
					],
				},
				{
					title: 'Video / Animation',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation',
							internal: false,
						},
						{
							title: 'Adobe After Effects',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/after-effects-customization',
							internal: false,
						},
						{
							title: 'Video Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/video-editing',
							internal: false,
						},
						{
							title: '2D Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/2d-animation',
							internal: false,
						},
						{
							title: '3D Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/3d-animation',
							internal: false,
						},
						{
							title: 'eCommerce / Amazon Product Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/amazon-ecommerce-product-videos',
							internal: false,
						},
						{
							title: 'Music Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/music-videos',
							internal: false,
						},
						{
							title: 'Short Video Ads',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/short-video-ads',
							internal: false,
						},
						{
							title: 'Storyboarding',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/storyboarding',
							internal: false,
						},
						{
							title: 'Twitch / YouTube / Stream Channel Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/twitch-youtube-stream-channel-animation',
							internal: false,
						},
						{
							title: 'Gif Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/gif-animation',
							internal: false,
						},
						{
							title: 'Whiteboard Animation / Explainer Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/whiteboard-animation-explainer-videos',
							internal: false,
						},
						{
							title: 'Anime',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/anime',
							internal: false,
						},
						{
							title: 'Logo Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/logo-animation',
							internal: false,
						},
						{
							title: 'Visual Effects (VFX)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/visual-effects-vfx',
							internal: false,
						},
						{
							title: 'Motion Graphics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/motion-graphics',
							internal: false,
						},
						{
							title: 'Intros / Outros',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/intros-outros',
							internal: false,
						},
						{
							title: 'eLearning Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/eLearning-Videos',
							internal: false,
						},
						{
							title: 'Lottie Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/lottie-animation',
							internal: false,
						},
						{
							title: 'Screencasting Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/screencasting-videos',
							internal: false,
						},
						{
							title: 'Character Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/character-animation',
							internal: false,
						},
						{
							title: 'Book / Game Video Trailers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/book-game-video-trailers',
							internal: false,
						},
						{
							title: 'Stop-Motion Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/stop-motion',
							internal: false,
						},
						{
							title: 'Claymation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/claymation',
							internal: false,
						},
						{
							title: 'Special Effects (FX)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/special-effects-fx',
							internal: false,
						},
						{
							title: 'Slideshow Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/slideshow-videos',
							internal: false,
						},
						{
							title: 'Other Video / Animation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation/other-video-animation',
							internal: false,
						},
					],
				},
				{
					title: 'Illustration',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration',
							internal: false,
						},
						{
							title: 'Drawing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/drawing',
							internal: false,
						},
						{
							title: 'Realistic Illustration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/realistic-illustration',
							internal: false,
						},
						{
							title: 'Digital Illustration / Splash Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/digital-illustration',
							internal: false,
						},
						{
							title: 'Pixel Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/pixel-art',
							internal: false,
						},
						{
							title: 'Tattoo Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/tattoo-design',
							internal: false,
						},
						{
							title: 'Line Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/line-art',
							internal: false,
						},
						{
							title: 'Sketching',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/sketching',
							internal: false,
						},
						{
							title: 'Comic / Cartoon',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/comic-cartoon',
							internal: false,
						},
						{
							title: 'Pen / Ink',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/pen-ink',
							internal: false,
						},
						{
							title: 'Narrative Illustration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/narrative-illustration',
							internal: false,
						},
						{
							title: 'Ink',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/ink-illustration',
							internal: false,
						},
						{
							title: 'Concept Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/concept-art',
							internal: false,
						},
						{
							title: 'Pencil Drawings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/pencil-drawings',
							internal: false,
						},
						{
							title: 'Clipping Path / Background Removal',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/clipping-path',
							internal: false,
						},
						{
							title: 'Advertising Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/advertising-art',
							internal: false,
						},
						{
							title: 'Watercolor',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/Watercolor',
							internal: false,
						},
						{
							title: 'Acrylic Artwork',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/acrylic-illustration',
							internal: false,
						},
						{
							title: 'Caricature',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/Caricatures',
							internal: false,
						},
						{
							title: 'Anime / Manga',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/anime%20/%20manga',
							internal: false,
						},
						{
							title: '3D Art / Illustrations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/3d-art-illustrations',
							internal: false,
						},
						{
							title: 'Vector Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/vector-art',
							internal: false,
						},
						
						{
							title: 'Pattern Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/pattern-design',
							internal: false,
						},
						{
							title: 'Medical Illustrations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/medical-illustration',
							internal: false,
						},
						{
							title: 'Specialized Mediums',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/specialized-mediums',
							internal: false,
						},
						{
							title: 'Architectural Illustration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/architectural-illustration',
							internal: false,
						},
						{
							title: 'Animals & Nature',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/animals-nature',
							internal: false,
						},
						{
							title: 'Calligraphy',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/calligraphy',
							internal: false,
						},
						
						
						{
							title: 'Packaging / Branding Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration/packaging-branding-art',
							internal: false,
						},
					],
				},
				{
					title: 'Voice Over / Acting',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting',
							internal: false,
						},
						{
							title: 'Spokespersons Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/spokespersons-videos',
							internal: false,
						},
						{
							title: 'Video Narration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/video-narration',
							internal: false,
						},
						{
							title: 'Television Voiceovers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/television',
							internal: false,
						},
						{
							title: 'Radio Voiceovers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/radio',
							internal: false,
						},
						{
							title: 'Audiobook',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/audiobook',
							internal: false,
						},
						{
							title: 'Podcasting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/podcasting',
							internal: false,
						},
						{
							title: 'Phone System / IVR',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/phone-system-ivr',
							internal: false,
						},
						{
							title: 'Dubbing / Impersonation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/dubbing-impersonation',
							internal: false,
						},
						{
							title: 'Educational eLearning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/educational-elearning',
							internal: false,
						},
						{
							title: 'Movie Trailers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting/movie-trailers',
							internal: false,
						},
						
					],
				},
				{
					title: 'Musicians / Singers',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers',
							internal: false,
						},
						{
							title: 'Singer-Songwriters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/singer-songwriters',
							internal: false,
						},
						{
							title: 'Pop',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/pop',
							internal: false,
						},
						{
							title: 'Hip Hop',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/hip-hop',
							internal: false,
						},
						{
							title: 'R&B',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/rb',
							internal: false,
						},
						{
							title: 'Rock Music',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/rock',
							internal: false,
						},
						{
							title: 'Disc Jockeys',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/disc-jockeys',
							internal: false,
						},
						{
							title: 'Folk',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/folk',
							internal: false,
						},
						{
							title: 'Opera / Classical',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/opera-classical',
							internal: false,
						},
						{
							title: 'Electronic Music',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/electronic',
							internal: false,
						},	
						{
							title: 'Blues',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/blues',
							internal: false,
						},
						{
							title: 'Jazz',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/jazz',
							internal: false,
						},
						{
							title: 'Punk Music',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/punk',
							internal: false,
						},
						{
							title: 'Country and Western',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/country',
							internal: false,
						},
						{
							title: 'Heavy Metal',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/heavy',
							internal: false,
						},
						{
							title: 'Reggae',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/reggae',
							internal: false,
						},
						{
							title: 'All Music Styles',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers/any-all-music-styles',
							internal: false,
						},
					],
				},
				{
					title: 'Book / Magazine Design',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design',
							internal: false,
						},
						{
							title: 'Book Cover Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/book-cover-design',
							internal: false,
						},
						{
							title: 'Interior Book Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/interior-book-design',
							internal: false,
						},
						{
							title: 'eBook Cover Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/ebook-cover-design',
							internal: false,
						},
						{
							title: 'Magazine Cover Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/magazine-cover-design',
							internal: false,
						},
						{
							title: 'Book Illustrations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/book-illustrations',
							internal: false,
						},
						{
							title: 'Book Layout Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/book-layout-design',
							internal: false,
						},
						{
							title: 'Other Book / Magazine Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/other-book-magazine-design',
							internal: false,
						},
						{
							title: 'Back / Spine Designs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/back-spine-designs',
							internal: false,
						},
						{
							title: '3D Mockup',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design/3d-mockup',
							internal: false,
						},
					],
				},
				{
					title: 'Audio / Sound / Music',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music',
							internal: false,
						},
						{
							title: 'Music Production',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/music-production',
							internal: false,
						},
						{
							title: 'Music Composition',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/music-composition',
							internal: false,
						},
						{
							title: 'Podcast / Spoken-Word Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/podcast-spoken-word-editing',
							internal: false,
						},
						{
							title: 'Jingles / Drops',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/jingles-drops',
							internal: false,
						},
						{
							title: 'Audio Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/audio-editing',
							internal: false,
						},
						{
							title: 'Audio Post Production',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/audio-post-production',
							internal: false,
						},
						{
							title: 'Vocal Tuning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/vocal-tuning',
							internal: false,
						},
						{
							title: 'Music Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/music-videos',
							internal: false,
						},
						{
							title: 'Adobe Audition',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/adobe-audition',
							internal: false,
						},
						{
							title: 'Sound Engineering',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/sound-engineering',
							internal: false,
						},
						{
							title: 'Radio',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/radio',
							internal: false,
						},
						{
							title: 'Sound Effects',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/vocal-tuning',
							internal: false,
						},
						{
							title: 'Sound Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/sound-design',
							internal: false,
						},
						{
							title: 'Music Transcription',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/music-transcription',
							internal: false,
						},
						{
							title: 'Audio Mastering',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/audio-mastering',
							internal: false,
						},
						{
							title: 'Audio Mixing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/audio-mixing',
							internal: false,
						},
						{
							title: 'Sound Production',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/sound-production',
							internal: false,
						},
						{
							title: 'Audio Recording',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/audio-recording',
							internal: false,
						},
						{
							title: 'Sony Acid Pro',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/sony-acid-pro',
							internal: false,
						},
						{
							title: 'Sound Forge',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/sound-forge',
							internal: false,
						},
						{
							title: 'Broadcasting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/broadcasting',
							internal: false,
						},
						{
							title: 'Mashups / Remixing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music/mashups-remixing',
							internal: false,
						},
					],
				},
				{
					title: 'File Conversions',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions',
							internal: false,
						},
						{
							title: 'Convert to Amazon Kindle',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-kindle',
							internal: false,
						},
						{
							title: 'Convert to ePub',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-epub',
							internal: false,
						},
						{
							title: 'Convert to HTML',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-html',
							internal: false,
						},
						{
							title: 'Convert to DOC / DOCX',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-editable-file',
							internal: false,
						},
						{
							title: 'Video File Conversions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/video-conversions',
							internal: false,
						},
						{
							title: 'Audio File Conversions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/audio-file-conversions',
							internal: false,
						},
						{
							title: 'PSD to WordPress',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/psd-to-wordpress',
							internal: false,
						},
						{
							title: 'Convert to PDF',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-pdf',
							internal: false,
						},
						{
							title: 'Convert to eBook',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-ebook',
							internal: false,
						},
						{
							title: 'PSD conversions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/psd-to-HTML-and-others',
							internal: false,
						},
						{
							title: 'Image File Conversions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/image-file-conversions',
							internal: false,
						},
						{
							title: 'Sketch to HTML',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/sketch-to-html',
							internal: false,
						},				
						{
							title: 'PSD to Email Templates',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/psd-to-email-templates',
							internal: false,
						},
						{
							title: 'Lightning Source / Ingram Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/lightning-source-ingram-support',
							internal: false,
						},
						{
							title: 'Rich Text conversions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/convert-to-rtf',
							internal: false,
						},
						{
							title: 'Other File Conversions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions/other-file-conversions',
							internal: false,
						},
					],
				},
				{
					title: 'Business / Advertising Design',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design',
							internal: false,
						},
						{
							title: 'Business Card Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/business-card-design',
							internal: false,
						},
						{
							title: 'Postcard / Flyer Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/postcard-flyer-design',
							internal: false,
						},
						{
							title: 'Poster Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/poster-design',
							internal: false,
						},
						{
							title: 'Landing Page Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/landing-page-design',
							internal: false,
						},
						{
							title: 'Twitch Store Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/twitch-store-design',
							internal: false,
						},
						{
							title: 'Email Newsletter Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/email-newsletter-design',
							internal: false,
						},
						{
							title: 'Brochure Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/brochure-design',
							internal: false,
						},
						{
							title: 'Menu Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/menu-design',
							internal: false,
						},
						{
							title: 'Album Cover Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/album-cover-design',
							internal: false,
						},
						{
							title: 'Direct Mail Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/direct-mail-design',
							internal: false,
						},
						{
							title: 'Email Template Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/email-template-design',
							internal: false,
						},
						{
							title: 'Website Header Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/website-header-design',
							internal: false,
						},
						{
							title: 'Word Template Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/word-template-design',
							internal: false,
						},
						{
							title: 'Resume Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/resume-design',
							internal: false,
						},
						{
							title: 'Booklet Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/booklet-design',
							internal: false,
						},
						{
							title: 'PowerPoint Template Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/powerpoint-template-design',
							internal: false,
						},
						{
							title: 'Graphs / Infographics / Reports',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/graphs-infographics-reports',
							internal: false,
						},
						{
							title: 'Invitation Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/invitation-design',
							internal: false,
						},
						{
							title: 'Pamphlet Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/pamphlet-design',
							internal: false,
						},
						{
							title: 'Car / Truck / Van Wrap Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/car-truck-van-wrap-design',
							internal: false,
						},
						{
							title: 'Icon Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/icon-design',
							internal: false,
						},
						{
							title: 'Leaflet Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/leaflet-design',
							internal: false,
						},
						{
							title: 'Billboard Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/billboard-design',
							internal: false,
						},
						{
							title: 'Catalog Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/catalog-design',
							internal: false,
						},
						{
							title: 'Banner Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/banner-design',
							internal: false,
						},
						{
							title: 'Trade Show Banner Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/trade-show-banner-design',
							internal: false,
						},
						{
							title: 'Trade Show Booth Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/trade-show-booth-design',
							internal: false,
						},
						{
							title: 'Other Business / Advertising Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/other-business-advertising-design',
							internal: false,
						},
						{
							title: 'Trade Show Banner Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design/trade-show-banner-design',
							internal: false,
						},
					],
				},		
				{
					title: 'T-Shirts / Merchandise Design',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design',
							internal: false,
						},
						{
							title: 'Custom T-Shirt Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/custom-t-shirt-design',
							internal: false,
						},
						{
							title: 'Clothing / Apparel Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/clothing-apparel-design',
							internal: false,
						},
						{
							title: 'Jersey Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/jersey-design',
							internal: false,
						},
						{
							title: 'Bag / Tote Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/bag-tote-design',
							internal: false,
						},
						{
							title: 'Cup / Mug Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/cup-mug-design',
							internal: false,
						},
						{
							title: 'Sticker Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/sticker-design',
							internal: false,
						},
						{
							title: 'Sweatshirt / Hoodie Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/sweatshirt-hoodie-design',
							internal: false,
						},
						{
							title: 'Merchandise Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/merchandise-design',
							internal: false,
						},
						{
							title: 'Hat / Cap Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/hat-cap-design',
							internal: false,
						},
						{
							title: 'Other Clothing / Accessories Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design/other-clothing-fashion-accessories-design',
							internal: false,
						},
					],
				},
				{
					title: 'Packaging / Label Design',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design',
							internal: false,
						},
						{
							title: 'Product Packaging Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/product-packaging-design',
							internal: false,
						},
						{
							title: 'Retail Packaging Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/retail-packaging-design',
							internal: false,
						},
						{
							title: 'Beer Label Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/beer-label-design',
							internal: false,
						},
						{
							title: 'Wine Label Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/wine-label-design',
							internal: false,
						},
						{
							title: 'Cosmetics Packaging Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/cosmetics-packaging-design',
							internal: false,
						},
						{
							title: 'Beverage Label Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/beverage-label-design',
							internal: false,
						},
						{
							title: 'Food Label Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/food-label-design',
							internal: false,
						},
						{
							title: 'Product Label Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/product-label-design',
							internal: false,
						},
						{
							title: 'Other Packaging / Label Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/other-packaging-label-design',
							internal: false,
						},
						{
							title: 'Food Packaging Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design/food-packaging-design',
							internal: false,
						},
						
					],
				},
				{
					title: 'CAD / Technical Drawings',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings',
							internal: false,
						},
						{
							title: 'CAD / Drafting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/cad-drafting',
							internal: false,
						},
						{
							title: 'Interior Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/interior-design',
							internal: false,
						},
						{
							title: 'Autodesk 3ds Max',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/autodesk-3ds-max',
							internal: false,
						},
						{
							title: '2D Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/2d-design',
							internal: false,
						},
						{
							title: '3D Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/3d-design',
							internal: false,
						},
						{
							title: '3D Rendering',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/3d-rendering',
							internal: false,
						},
						{
							title: 'Product Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/product-development',
							internal: false,
						},
						{
							title: '3D Modeling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/3d-modeling',
							internal: false,
						},
						{
							title: 'Prototyping',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/prototyping',
							internal: false,
						},
						{
							title: 'Schematic Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/schematic-design',
							internal: false,
						},
						{
							title: 'Autodesk Revit',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/autodesk-revit',
							internal: false,
						},
						{
							title: 'Construction Drawings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/construction-drawings',
							internal: false,
						},
						{
							title: 'CATIA',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/catia',
							internal: false,
						},
						{
							title: 'Mold Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/mold-design',
							internal: false,
						},
						{
							title: 'Machine Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/machine-design',
							internal: false,
						},
						{
							title: 'ArchiCAD',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/archicad',
							internal: false,
						},
						{
							title: 'Augmented Reality (AR)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/augmented-reality-ar',
							internal: false,
						},
						{
							title: 'SketchUp',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/sketchup',
							internal: false,
						},
						{
							title: 'Autodesk Inventor',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/autodesk-inventor',
							internal: false,
						},
						{
							title: 'Machine Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/machine-design',
							internal: false,
						},
						{
							title: 'ArchiCAD',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/archicad',
							internal: false,
						},
						{
							title: 'Sheet Metal Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/sheet-metal-design',
							internal: false,
						},
						{
							title: 'SolidWorks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/solidworks',
							internal: false,
						},
						{
							title: 'Virtual Staging',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/virtual-staging',
							internal: false,
						},
						{
							title: 'Sustainable Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/sustainable-design',
							internal: false,
						},
						{
							title: 'Space Planning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/space-planning',
							internal: false,
						},
						{
							title: 'Landscape Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/landscape-design',
							internal: false,
						},
						{
							title: 'Furniture Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/furniture-design',
							internal: false,
						},
						{
							title: 'Lighting Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/lighting-design',
							internal: false,
						},
						{
							title: 'Set Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings/set-design',
							internal: false,
						},
					],
				},
				{
					title: 'Image Restoration / Editing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing',
							internal: false,
						},
						{
							title: 'Photo Retouching',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/photo-retouching',
							internal: false,
						},
						{
							title: 'Skin Retouching Services',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/skin-retouching-services',
							internal: false,
						},
						{
							title: 'Noise Reduction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/noise-reduction',
							internal: false,
						},
						{
							title: 'Adobe Lightroom',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/adobe-lightroom',
							internal: false,
						},
						{
							title: 'Beauty Retouching',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/beauty-retouching',
							internal: false,
						},
						{
							title: 'Chroma Key',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/chroma-key',
							internal: false,
						},
						{
							title: 'Photo / Image Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/photo-image-editing',
							internal: false,
						},
						{
							title: 'Photo Illustration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/photo-illustration',
							internal: false,
						},
						{
							title: 'Ghost Mannequin',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/ghost-mannequin',
							internal: false,
						},
						{
							title: 'Object Removal',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/object-removal',
							internal: false,
						},
						{
							title: 'Image Resizing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/image-resizing',
							internal: false,
						},
						{
							title: 'Image Masking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/image-masking',
							internal: false,
						},
						{
							title: 'Photo / Image Cropping',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/photo-image-cropping',
							internal: false,
						},
						{
							title: 'Paint.NET',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/paintnet',
							internal: false,
						},
						{
							title: 'Color Balancing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/color-balancing',
							internal: false,
						},
						{
							title: 'Deep Etching',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/deep-etching',
							internal: false,
						},
						{
							title: 'Hair Masking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/hair-masking',
							internal: false,
						},
						{
							title: 'Watermarking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing/watermarking',
							internal: false,
						},
					],
				},
				{
					title: 'AI Art Services',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/ai-art-services',
							internal: false,
						},
						{
							title: 'AI Images & Avatars',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/ai-art-services/ai-images-avatars',
							internal: false,
						},
						{
							title: 'Midjourney Artists',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/ai-art-services/midjourney-artists',
							internal: false,
						},
						{
							title: 'AI Video',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/ai-art-services/ai-video',
							internal: false,
						},
						{
							title: 'Stable Diffusion AI Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/ai-art-services/stable-diffusion-ai-artists',
							internal: false,
						},
						{
							title: 'AI Audio',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/ai-art-services/ai-audio',
							internal: false,
						},
					],
				},
				{
					title: 'Christian Services',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services',
							internal: false,
						},
						{
							title: 'Christian Music',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services/christian-music',
							internal: false,
						},
						{
							title: 'Christian Videos',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services/christian-videos',
							internal: false,
						},
						{
							title: 'Christian Illustration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services/christian-illustration',
							internal: false,
						},
						{
							title: 'Christian Logo Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services/christian-logo-design',
							internal: false,
						},
						{
							title: 'Christian T-Shirts / Merch',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services/christian-t-shirts-merchandise',
							internal: false,
						},
						{
							title: 'Christian Book Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services/christian-book-design',
							internal: false,
						},
					],
				},
				{
					title: 'Photography',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography',
							internal: false,
						},
						{
							title: 'Adobe Lightroom',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/adobe-lightroom',
							internal: false,
						},
						{
							title: 'Compositing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/compositing',
							internal: false,
						},
						{
							title: 'Product Photography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/product-photography',
							internal: false,
						},
						{
							title: 'Commercial Photography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/commercial-photography',
							internal: false,
						},
						{
							title: 'Outdoor Photography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/outdoor-photography',
							internal: false,
						},
						{
							title: 'Advertising Photography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/advertising-photography',
							internal: false,
						},
						{
							title: 'Photorealism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography/photorealism',
							internal: false,
						},
					],
				},
				{
					title: 'Printing / Production',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production',
							internal: false,
						},
						{
							title: 'Printing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/printing',
							internal: false,
						},
						{
							title: 'Self Publishing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/self-publishing',
							internal: false,
						},
						{
							title: 'Color Printing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/color-printing',
							internal: false,
						},
						{
							title: 'Print Production',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/print-production',
							internal: false,
						},
						{
							title: 'Canvas Prints',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/canvas-prints',
							internal: false,
						},
						{
							title: 'Microsoft Publisher',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/microsoft-publisher',
							internal: false,
						},
						{
							title: 'Publication Production',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/publication-production',
							internal: false,
						},
						{
							title: 'Post-Production',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/post-production',
							internal: false,
						},
						{
							title: 'Prepress',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/prepress',
							internal: false,
						},
						{
							title: 'Desktop Publishing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/desktop-publishing-services',
							internal: false,
						},
						{
							title: 'Color Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/color-management',
							internal: false,
						},
						{
							title: '3D Printing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/3d-printing',
							internal: false,
						},
						{
							title: 'Commercial Printing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/commercial-printing',
							internal: false,
						},
						{
							title: 'Wide Format Printing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production/wide-format-printing',
							internal: false,
						},
					],
				},
				{
					title: 'Painting',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting',
							internal: false,
						},
						{
							title: 'Digital Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/digital-painting',
							internal: false,
						},
						{
							title: 'Oil Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/oil-painting',
							internal: false,
						},
						{
							title: 'Portrait Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/portrait-painting',
							internal: false,
						},
						{
							title: 'Abstract Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/abstract-painting',
							internal: false,
						},
						{
							title: 'Landscape Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/landscape-painting',
							internal: false,
						},
						{
							title: 'Pointillism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/pointillism',
							internal: false,
						},
						{
							title: 'Murals',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/murals',
							internal: false,
						},
						{
							title: 'Watercolor Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/watercolor-art',
							internal: false,
						},
						{
							title: 'Digital Printing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/digital-printing',
							internal: false,
						},
						{
							title: 'Acrylic Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/acrylic-painting',
							internal: false,
						},
						{
							title: 'Matte Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/matte-painting',
							internal: false,
						},
						{
							title: 'Still Life Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/still-life-painting',
							internal: false,
						},
						{
							title: 'Airbrushing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/airbrushing',
							internal: false,
						},
						{
							title: 'Pastels',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/pastels',
							internal: false,
						},
						{
							title: 'Realism Painting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting/realism-painting',
							internal: false,
						},
					],
				},
				{
					title: 'Cartoons / Comic Art',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art',
							internal: false,
						},
						{
							title: 'Graphic Novel Artist',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/graphic-novel-artist',
							internal: false,
						},
						{
							title: 'Cartooning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/cartooning',
							internal: false,
						},
						{
							title: 'Caricatures',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/caricatures',
							internal: false,
						},
						{
							title: 'Manga',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/manga',
							internal: false,
						},
						{
							title: 'Characters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/cartoon-characters',
							internal: false,
						},
						{
							title: 'Political Cartoon',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/political-cartoon',
							internal: false,
						},
						{
							title: 'Freestyle Drawing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/freestyle-drawing',
							internal: false,
						},
						{
							title: 'Comic Art',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art/comic-art',
							internal: false,
						},
					],
				},
				{
					title: 'Fashion',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion',
							internal: false,
						},
						{
							title: 'Embroidery Digitization',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/embroidery-digitization',
							internal: false,
						},
						{
							title: 'Fashion Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/fashion-design',
							internal: false,
						},
						{
							title: 'Costume Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/costume-design',
							internal: false,
						},
						{
							title: 'Pattern Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/pattern-design',
							internal: false,
						},
						{
							title: 'Fashion Illustration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/fashion-illustration',
							internal: false,
						},
						{
							title: 'Jewelry Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/jewelry-design',
							internal: false,
						},
						{
							title: 'Footwear Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/footwear-design',
							internal: false,
						},
						{
							title: 'Textile Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/textile-design',
							internal: false,
						},
						{
							title: 'Bag Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion/bag-design',
							internal: false,
						},
					],
				},
			],
		},
		{
			title: 'Bookings',
			navs: [
				{
					title: 'View All',
					strong: true,
					link:
						'https://www.hostjane.com/marketplace/categories/online-tutors',
					internal: false,
				},
				{
					title: 'Language Tutors',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors',
							internal: false,
						},
						{
							title: 'English Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/english-lessons',
							internal: false,
						},
						{
							title: 'Spanish Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/spanish-lessons',
							internal: false,
						},
						{
							title: 'French Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/french-lessons',
							internal: false,
						},
						{
							title: 'Italian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/italian-lessons',
							internal: false,
						},
						{
							title: 'German Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/german-lessons',
							internal: false,
						},
						{
							title: 'Japanese Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/japanese-lessons',
							internal: false,
						},
						{
							title: 'Chinese (Mandarin) Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/chinese-mandarin-lessons',
							internal: false,
						},
						{
							title: 'Russian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/russian-lessons',
							internal: false,
						},
						{
							title: 'Korean Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/korean-lessons',
							internal: false,
						},
						{
							title: 'Hindi Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/hindi-lessons',
							internal: false,
						},
						{
							title: 'ASL Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/asl-lessons',
							internal: false,
						},
						{
							title: 'Polish Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/polish-lessons',
							internal: false,
						},
						{
							title: 'Portuguese Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/portuguese-lessons',
							internal: false,
						},
						{
							title: 'Brazilian Portuguese Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/brazilian-portuguese',
							internal: false,
						},
						{
							title: 'Hebrew Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/hebrew-lessons',
							internal: false,
						},
						{
							title: 'Dutch Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/dutch-lessons',
							internal: false,
						},
						{
							title: 'Serbian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/serbian-lessons',
							internal: false,
						},
						{
							title: 'Arabic Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/arabic-lessons',
							internal: false,
						},
						{
							title: 'Turkish Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/turkish-lessons',
							internal: false,
						},
						{
							title: 'Malay Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/malay-lessons',
							internal: false,
						},
						{
							title: 'Bengali Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/bengali-lessons',
							internal: false,
						},
						{
							title: 'Danish Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/danish-lessons',
							internal: false,
						},
						{
							title: 'Norwegian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/norwegian-lessons',
							internal: false,
						},
						{
							title: 'Thai Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/thai-lessons',
							internal: false,
						},
						{
							title: 'Greek Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/greek-lessons',
							internal: false,
						},
						{
							title: 'Finnish Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/finnish-lessons',
							internal: false,
						},
						{
							title: 'Swedish Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/swedish-lessons',
							internal: false,
						},
						{
							title: 'Croatian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/croatia-lessons',
							internal: false,
						},
						{
							title: 'Urdu Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/urdu-lessons',
							internal: false,
						},
						{
							title: 'Tamil Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/tamil-lessons',
							internal: false,
						},
						{
							title: 'Ukrainian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/ukrainian-lessons',
							internal: false,
						},
						{
							title: 'Marathi Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/marathi-lessons',
							internal: false,
						},
						{
							title: 'Telugu Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/telugu-lessons',
							internal: false,
						},
						{
							title: 'Chinese Yue (Cantonese) Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/yue-cantonese-lessons',
							internal: false,
						},
						{
							title: 'Chinese (Wu) Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/chinese-wu-lessons',
							internal: false,
						},
						{
							title: 'Bengali Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/bengali-lessons',
							internal: false,
						},
						{
							title: 'Indonesian Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/indonesian-lessons',
							internal: false,
						},
						{
							title: 'Vietnamese Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/vietnamese-lessons',
							internal: false,
						},
						{
							title: 'Filipino / Tagalog Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/filipino-tagalog-lessons',
							internal: false,
						},
						{
							title: 'Other Languages',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors/other-language-tutors',
							internal: false,
						},
					],
				},
				{
					title: 'Live Chats',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats',
							internal: false,
						},
						{
							title: 'Therapists',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/therapists',
							internal: false,
						},
						{
							title: 'Astrology Readings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/astrology',
							internal: false,
						},
						{
							title: 'Tarot Card Readings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/tarot-readings',
							internal: false,
						},
						{
							title: 'Psychics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/psychics',
							internal: false,
						},
						{
							title: 'Spiritual Readings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/spiritual-readings',
							internal: false,
						},
						{
							title: 'Life Path & Advice',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/life-path-advice',
							internal: false,
						},
						{
							title: 'Financial Guidance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/financial-guidance',
							internal: false,
						},
						{
							title: 'Love & Relationships',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats/love-relationships',
							internal: false,
						},
					],
				},
				{
					title: 'Online Experiences',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons',
							internal: false,
						},
						{
							title: 'Advice / Friends / PenPals',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/general-advice',
							internal: false,
						},
						{
							title: 'Phone Chats',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/phone-calls',
							internal: false,
						},
						{
							title: 'Cooking Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/cooking-lessons',
							internal: false,
						},
						{
							title: 'Gaming / Twitch',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/gaming-twitch-lessons',
							internal: false,
						},
						{
							title: 'D.I.Y. / Repair Advice',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/do-it-yourself',
							internal: false,
						},
						{
							title: 'Beauty / Makeup Sessions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/makeup-artistry-lessons',
							internal: false,
						},
						{
							title: 'Pet Care Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/pet-care-lessons',
							internal: false,
						},
						{
							title: 'Travel Guides',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/travel-guides',
							internal: false,
						},
						{
							title: 'Real Estate Advice',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/house-buying-lessons',
							internal: false,
						},
						{
							title: 'Drinks / Bartending Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/drinks-bartending-lessons',
							internal: false,
						},
						{
							title: 'Dog Training Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/dog-training-lessons',
							internal: false,
						},
						{
							title: 'Cake Baking / Bread Making',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/cake-bread-baking-lessons',
							internal: false,
						},
						{
							title: 'Feng Shui Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/feng-shui-lessons',
							internal: false,
						},
						{
							title: 'Reflexology Techniques',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/reflexology-techniques',
							internal: false,
						},
						{
							title: 'Sport / Outdoors',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/sport-outdoors',
							internal: false,
						},
						{
							title: 'Chess Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons/chess-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'ASMR / Therapy',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development',
							internal: false,
						},
						{
							title: 'Online Counseling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/therapy-counseling-relationship-advice',
							internal: false,
						},
						{
							title: 'ASMR',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/autonomous-sensory-meridian-response',
							internal: false,
						},
						{
							title: 'Addiction Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/psychology-lessons',
							internal: false,
						},
						{
							title: 'Relationship Issues',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/self-esteem-support',
							internal: false,
						},
						{
							title: 'Religion & Spirituality',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/religion-spirituality',
							internal: false,
						},
						{
							title: 'Memory / Study Skills',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/memory-study-skills',
							internal: false,
						},
						{
							title: 'Parenting Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/parenting-lessons',
							internal: false,
						},
						{
							title: 'Critical Thinking Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/critical-thinking-training',
							internal: false,
						},
						{
							title: 'Public Speaking Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/public-speaking-training',
							internal: false,
						},
						{
							title: 'Communications Skills',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/communications-skills',
							internal: false,
						},
						{
							title: 'Learning Disability Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/learning-disability-support',
							internal: false,
						},

						{
							title: 'Personal Finance Help',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/personal-finance-help',
							internal: false,
						},
						{
							title: 'Personal Transformation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/personal-transformation',
							internal: false,
						},
						{
							title: 'Personal Brand Building',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/personal-brand-building',
							internal: false,
						},
						{
							title: 'Stress Management Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/stress-management-training',
							internal: false,
						},
						{
							title: 'Happiness Goals',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/happiness-goals',
							internal: false,
						},
						{
							title: 'Anger Management Classes',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/anger-management-classes',
							internal: false,
						},
						{
							title: 'Leadership Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/leadership-training',
							internal: false,
						},
						{
							title: 'Motivational Trainers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/motivational-trainers',
							internal: false,
						},
						{
							title: 'Career Development Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/career-development-support',
							internal: false,
						},
						{
							title: 'Productivity',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development/productivity',
							internal: false,
						},
					],
				},
				{
					title: 'Test Prep Tutors',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors',
							internal: false,
						},
						{
							title: 'SAT Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors/sat-lessons',
							internal: false,
						},
						{
							title: 'ACT Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors/act-lessons',
							internal: false,
						},
						{
							title: 'GRE Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors/gre-lessons',
							internal: false,
						},
						{
							title: 'LSAT Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors/lsat-training',
							internal: false,
						},
					],
				},
				{
					title: 'IT / Computing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing',
							internal: false,
						},
						{
							title: 'Web Design Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/web-design-lessons',
							internal: false,
						},
						{
							title: 'Cloud Computing Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/cloud-computing-lessons',
							internal: false,
						},
						{
							title: 'DevOps Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/devops-lessons',
							internal: false,
						},
						{
							title: 'Web Development Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/web-development-lessons',
							internal: false,
						},
						{
							title: 'Data Science Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/data-science-lessons',
							internal: false,
						},
						{
							title: 'Mobile Development Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/mobile-development-lessons',
							internal: false,
						},
						{
							title: 'IT Security Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/it-security-lessons',
							internal: false,
						},
						{
							title: 'IT Certification Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/it-certification-training',
							internal: false,
						},	
						{
							title: 'Network / System Admin Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/network-system-admin-lessons',
							internal: false,
						},
						{
							title: 'Database Management Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/database-management-lessons',
							internal: false,
						},
						{
							title: 'Operating System Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/operating-system-lessons',
							internal: false,
						},
						{
							title: 'Cryptography Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing/cryptography-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'Math Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons',
							internal: false,
						},
						{
							title: 'Algebra Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/algebra-lessons',
							internal: false,
						},
						{
							title: 'Prealgebra Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/prealgebra-lessons',
							internal: false,
						},
						{
							title: 'Geometry Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/geometry-lessons',
							internal: false,
						},
						{
							title: 'Trigonometry Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/trigonometry-lessons',
							internal: false,
						},
						{
							title: 'Elementary Math Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/elementary-math-lessons',
							internal: false,
						},
						{
							title: 'Arithmetic',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/arithmetic',
							internal: false,
						},
						{
							title: 'Precalculus',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/precalculus',
							internal: false,
						},
						{
							title: 'Calculus Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/calculus-lessons',
							internal: false,
						},
						{
							title: 'Statistics and Probability Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/statistics-and-probability-lessons',
							internal: false,
						},
						{
							title: 'AP College Calculus AB',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/ap-college-calculus-ab',
							internal: false,
						},
						{
							title: 'AP College Calculus BC',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons/ap-college-calculus-bc',
							internal: false,
						},
					],
				},
				{
					title: 'Investing & Trading Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons',
							internal: false,
						},
						{
							title: 'Stock Trading Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/stock-trading-lessons',
							internal: false,
						},
						{
							title: 'Investing Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/investing-lessons',
							internal: false,
						},
						{
							title: 'Financial Analysis Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/financial-analysis-lessons',
							internal: false,
						},
						{
							title: 'Forex Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/forex-lessons',
							internal: false,
						},
						{
							title: 'Technical Analysis Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/technical-analysis-lessons',
							internal: false,
						},
						{
							title: 'Options Trading Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/options-trading-lessons',
							internal: false,
						},
						{
							title: 'Financial Trading Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/financial-trading-lessons',
							internal: false,
						},
						{
							title: 'Day Trading Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/day-trading-lessons',
							internal: false,
						},
						{
							title: 'Algorithmic Trading Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons/algorithmic-trading-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'Music Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons',
							internal: false,
						},
						{
							title: 'Music Software Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/music-software-training',
							internal: false,
						},
						{
							title: 'Flute Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/flute-lessons',
							internal: false,
						},
						{
							title: 'Harmonica Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/harmonica-lessons',
							internal: false,
						},
						{
							title: 'Music Production Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/music-production-lessons',
							internal: false,
						},
						{
							title: 'Keyboard Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/keyboard-lessons',
							internal: false,
						},
						{
							title: 'Piano Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/piano-lessons',
							internal: false,
						},
						{
							title: 'Electric Guitar Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/electric-guitar-lessons',
							internal: false,
						},
						{
							title: 'Classical Guitar Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/guitar-lessons',
							internal: false,
						},
						{
							title: 'Bass Guitar Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/bass-guitar-lessons',
							internal: false,
						},
						{
							title: 'Harp Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/harp-lessons',
							internal: false,
						},
						{
							title: 'Recorder Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/recorder-lessons',
							internal: false,
						},
						{
							title: 'Violin Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/violin-lessons',
							internal: false,
						},
						{
							title: 'Cello Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/cello-lessons',
							internal: false,
						},
						{
							title: 'Vocal Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/vocal-lessons',
							internal: false,
						},
						{
							title: 'Clarinet Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/clarinet-lessons',
							internal: false,
						},
						{
							title: 'Saxophone Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/saxophone-lessons',
							internal: false,
						},
						{
							title: 'Drum Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/drum-lessons',
							internal: false,
						},
						{
							title: 'Trumpet Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/trumpet-lessons',
							internal: false,
						},
						{
							title: 'Ukulele Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/ukulele-lessons',
							internal: false,
						},
						{
							title: 'General Music Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons/other-musical-instrument-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'Finance / Accounting Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons',
							internal: false,
						},
						{
							title: 'Accounting / Bookkeeping Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/accounting-bookkeeping-lessons',
							internal: false,
						},
						{
							title: 'Cryptocurrency / Blockchain Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/cryptocurrency-blockchain-lessons',
							internal: false,
						},
						{
							title: 'Finance Cert & Exam Prep',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/finance-cert-exam-prep',
							internal: false,
						},
						{
							title: 'Economics Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/economics-lessons',
							internal: false,
						},
						{
							title: 'Money Management Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/money-management-training',
							internal: false,
						},
						{
							title: 'Financial Modeling / Analysis Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/financial-modeling-analysis-training',
							internal: false,
						},
						{
							title: 'Financial Compliance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons/compliance-training',
							internal: false,
						},
					],
				},
				{
					title: 'Online Interpreting Services',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services',
							internal: false,
						},
						{
							title: 'Simultaneous Interpreting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services/simultaneous-interpreting',
							internal: false,
						},
						{
							title: 'Over-the-Phone Interpretation (OPI)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services/over-the-phone-interpretation-opi',
							internal: false,
						},
						{
							title: 'Consecutive Interpreting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services/consecutive-interpreting',
							internal: false,
						},
						{
							title: 'On-Demand Phone Interpreting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services/on-demand-phone-interpreting',
							internal: false,
						},
						{
							title: 'Whisper Interpreting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services/whisper-interpreting',
							internal: false,
						},
						{
							title: 'Travel Interpreting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services/escorttravel-interpreting',
							internal: false,
						},
					],
				},
				{
					title: 'Business Training',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training',
							internal: false,
						},
						{
							title: 'Professional Development Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/professional-development-lessons',
							internal: false,
						},
						{
							title: 'Business Software Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/business-software-lessons',
							internal: false,
						},
						{
							title: 'Accounting / Finance Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/accounting-finance-lessons',
							internal: false,
						},
						{
							title: 'Marketing Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/marketing-lessons',
							internal: false,
						},
						{
							title: 'Project Management Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/project-management-lessons',
							internal: false,
						},
						{
							title: 'Sales Strategy Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/sales-strategy-lessons',
							internal: false,
						},
						{
							title: 'Customer Service Resources',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/customer-service-resources',
							internal: false,
						},
						{
							title: 'Human Resources Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/human-resources-support',
							internal: false,
						},
						{
							title: 'Small Business Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/small-business-support',
							internal: false,
						},
						{
							title: 'Business Law Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/business-law-lessons',
							internal: false,
						},
						{
							title: 'Real Estate Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/real-estate-training',
							internal: false,
						},
						{
							title: 'Management Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/management-training',
							internal: false,
						},
						{
							title: 'Entrepreneurship Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training/entrepreneurship-support',
							internal: false,
						},
					],
				},
				{
					title: 'Science Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons',
							internal: false,
						},
						{
							title: 'Physics Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/physics-lessons',
							internal: false,
						},
						{
							title: 'Chemistry Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/chemistry-lessons',
							internal: false,
						},
						{
							title: 'Biology Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/biology-lessons',
							internal: false,
						},
						{
							title: 'Engineering Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/engineering-lessons',
							internal: false,
						},
						{
							title: 'AP Physics Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/ap-physics-lessons',
							internal: false,
						},
						{
							title: 'Applied Science Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/applied-science-lessons',
							internal: false,
						},
						{
							title: 'Scientific Method Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/scientific-method-lessons',
							internal: false,
						},
						{
							title: 'Health Science Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/health-science-lessons',
							internal: false,
						},
						{
							title: 'Space and Astronomy Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons/space-and-astronomy-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'English Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons',
							internal: false,
						},
						{
							title: 'Grammar Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/grammar-lessons',
							internal: false,
						},
						{
							title: 'Punctuation Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/punctuation-lessons',
							internal: false,
						},
						{
							title: 'Writing Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/writing-lessons',
							internal: false,
						},
						{
							title: 'English Pronunciation Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/english-pronunciation-lessons',
							internal: false,
						},
						{
							title: 'English Vocabulary Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/english-vocabulary-lessons',
							internal: false,
						},
						{
							title: 'ESL/ESOL Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/esl-lessons',
							internal: false,
						},
						{
							title: 'IELTS Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/ielts-support',
							internal: false,
						},
						{
							title: 'Business English Courses',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/business-english-courses',
							internal: false,
						},
						{
							title: 'Semantics Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/semantics-lessons',
							internal: false,
						},
						{
							title: 'Etymology Study',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons/etymology-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'Creative / Design Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons',
							internal: false,
						},
						{
							title: 'Animation / Illustration Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/animation-illustration-lessons',
							internal: false,
						},
						{
							title: 'Graphic Design Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/graphic-design-lessons',
							internal: false,
						},
						{
							title: 'Photography Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/photography-lessons',
							internal: false,
						},
						{
							title: 'Video Editing Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/video-editing-lessons',
							internal: false,
						},
						{
							title: 'Acting / Drama / Comedy',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/acting-drama-comedy-theater',
							internal: false,
						},
						{
							title: 'Motion Graphics and VFX Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/motion-graphics-and-vfx-lessons',
							internal: false,
						},
						{
							title: 'Drawing Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/drawing-lessons',
							internal: false,
						},
						{
							title: 'Typography Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/typography-lessons',
							internal: false,
						},
						{
							title: 'Design Thinking Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/design-thinking-training',
							internal: false,
						},
						{
							title: 'User Experience Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons/user-experience-training',
							internal: false,
						},
					],
				},
				{
					title: 'Teacher Training',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training',
							internal: false,
						},
						{
							title: 'Instructional Design Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training/instructional-design-training',
							internal: false,
						},
						{
							title: 'Presentation / Public Speaking Skills',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training/presentation-public-speaking-skills',
							internal: false,
						},
						{
							title: 'Train the Trainer Courses',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training/train-the-trainer-courses',
							internal: false,
						},
						{
							title: 'Moodle Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training/moodle-training',
							internal: false,
						},
					],
				},
				{
					title: 'History Lessons',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons',
							internal: false,
						},
						{
							title: 'Government & Politics Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/government-politics-lessons',
							internal: false,
						},
						{
							title: 'US History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/us-history-lessons',
							internal: false,
						},
						{
							title: 'Diplomatic History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/diplomatic-history-lessons',
							internal: false,
						},
						{
							title: 'Military History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/military-history-lessons',
							internal: false,
						},
						{
							title: 'Environmental History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/environmental-history-lessons',
							internal: false,
						},
						{
							title: 'World History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/world-history-lessons',
							internal: false,
						},
						{
							title: 'Gender History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/gender-history-lessons',
							internal: false,
						},
						{
							title: 'Social History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/social-history-lessons',
							internal: false,
						},
						{
							title: 'Anthropology / Cultural History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/cultural-history-lessons',
							internal: false,
						},
						{
							title: 'History of Religion Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/history-of-religion-lessons',
							internal: false,
						},
						{
							title: 'Economic History Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons/economic-history-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'Theology Classes',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology',
							internal: false,
						},
						{
							title: 'Exegetical Theology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/exegetical-theology',
							internal: false,
						},
						{
							title: 'Systematic Theology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/systematic-theology',
							internal: false,
						},
						{
							title: 'Philosophical Theology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/philosophical-theology',
							internal: false,
						},
						{
							title: 'Biblical Theology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/biblical-theology',
							internal: false,
						},
						{
							title: 'Historical Theology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/historical-theology',
							internal: false,
						},
						{
							title: 'Practical Theology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/practical-theology',
							internal: false,
						},
						{
							title: 'Judaism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/judaism',
							internal: false,
						},
						{
							title: 'Hinduism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/hinduism',
							internal: false,
						},
						{
							title: 'Buddhism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/buddhism',
							internal: false,
						},
						{
							title: 'Shinto',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/shinto',
							internal: false,
						},
						{
							title: 'Modern Paganism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology/paganism',
							internal: false,
						},
					],
				},
				{
					title: 'Health & Fitness Teachers',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers',
							internal: false,
						},
						{
							title: 'Online Fitness Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/online-fitness-lessons',
							internal: false,
						},
						{
							title: 'Dieting Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/dieting-support',
							internal: false,
						},
						{
							title: 'General Health Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/general-health-lessons',
							internal: false,
						},
						{
							title: 'Mental Health Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/mental-health-support',
							internal: false,
						},
						{
							title: 'Online Dance Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/online-dance-lessons',
							internal: false,
						},
						{
							title: 'Meditation Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/meditation-lessons',
							internal: false,
						},
						{
							title: 'Yoga Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/yoga-lessons',
							internal: false,
						},
						{
							title: 'Nutrition Education',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/nutrition-education',
							internal: false,
						},
						{
							title: 'Safety / First Aid Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/safety-first-aid-training',
							internal: false,
						},
						{
							title: 'Sports Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/sports-training',
							internal: false,
						},
						{
							title: 'Alternative Medicine',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/alternative-medicine-lessons',
							internal: false,
						},
						{
							title: 'Self Defense Lessons',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers/self-defense-lessons',
							internal: false,
						},
					],
				},
				{
					title: 'Office Productivity',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity',
							internal: false,
						},
						{
							title: 'Computer Literacy',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity/computer-literacy',
							internal: false,
						},
						{
							title: 'Google Product Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity/google-product-training',
							internal: false,
						},
						{
							title: 'Apple Product Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity/apple-product-training',
							internal: false,
						},
						{
							title: 'Microsoft Product Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity/microsoft-product-training',
							internal: false,
						},
						{
							title: 'SAP Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity/sap-training',
							internal: false,
						},
						{
							title: 'Oracle Training',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity/oracle-training',
							internal: false,
						},
					],
				},
			],
		},
		{
			title: 'Writing / Translation',
			navs: [
				{
					title: 'View All',
					strong: true,
					link:
						'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
					internal: false,
				},
				{
					title: 'Translation',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation',
							internal: false,
						},
						{
							title: 'English Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/english-translation',
							internal: false,
						},
						{
							title: 'Chinese (Simplified) Translation',
							strong: false,
							link:
								'hhttps://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/chinese-simplified-translation',
							internal: false,
						},
						{
							title: 'French Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/french-translation',
							internal: false,
						},
						{
							title: 'Italian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/italian-translation',
							internal: false,
						},
						{
							title: 'Japanese Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/japanese-translation',
							internal: false,
						},
						{
							title: 'Spanish Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/spanish-translation',
							internal: false,
						},
						{
							title: 'General Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/general-translation',
							internal: false,
						},
						{
							title: 'Portuguese (Brazil) Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/portuguse-brazil-translation',
							internal: false,
						},
						{
							title: 'Portuguese Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/portuguese-translation',
							internal: false,
						},
						{
							title: 'Russian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/russian-translation',
							internal: false,
						},
						{
							title: 'German Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/german-translation',
							internal: false,
						},
						{
							title: 'Chinese (Traditional) Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/chinese-traditional-traditional',
							internal: false,
						},
						{
							title: 'Hebrew Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/hebrew-translationn',
							internal: false,
						},
						{
							title: 'Legal Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/legal-translation',
							internal: false,
						},
						{
							title: 'Technical Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/technical-translation',
							internal: false,
						},
						{
							title: 'Other Languages',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/other-languages-translation',
							internal: false,
						},
						{
							title: 'Greek Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/greek-translation',
							internal: false,
						},
						{
							title: 'Bengali Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/bengali-translation',
							internal: false,
						},
						{
							title: 'Swedish Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/swedish-translation',
							internal: false,
						},
						{
							title: 'Arabic Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/arabic-translation',
							internal: false,
						},
						{
							title: 'Romanian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/romanian-translation',
							internal: false,
						},
						{
							title: 'Vietnamese Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/vietnamese-translation',
							internal: false,
						},
						{
							title: 'Czech Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/czech-translation',
							internal: false,
						},
						{
							title: 'Dutch Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/dutch-translation',
							internal: false,
						},
						{
							title: 'Polish Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/polish-translation',
							internal: false,
						},
						{
							title: 'Hindi Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/hindi-translation',
							internal: false,
						},
						{
							title: 'Ukrainian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/ukrainian-translation',
							internal: false,
						},
						{
							title: 'Norwegian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/norwegian-ttranslation',
							internal: false,
						},
						{
							title: 'Bulgarian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/bulgarian-translation',
							internal: false,
						},
						{
							title: 'Spanish (Latin American) Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/spanish-latin-american-translation',
							internal: false,
						},
						{
							title: 'Thai Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/thai-translation',
							internal: false,
						},
						{
							title: 'French (Canada) Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/french-canada-translation',
							internal: false,
						},
						{
							title: 'Tagalog Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/tagalog-translation',
							internal: false,
						},
						{
							title: 'Malay Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/malay-translation',
							internal: false,
						},
						{
							title: 'Finnish Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/finnish-translation',
							internal: false,
						},
						{
							title: 'Danish Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/danish-translation',
							internal: false,
						},
						{
							title: 'Hungarian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/hungarian-translation',
							internal: false,
						},
						{
							title: 'Slovak Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/slovak-translation',
							internal: false,
						},
						{
							title: 'Persian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/Persian-translation',
							internal: false,
						},
						{
							title: 'Serbian Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/serbian-translation',
							internal: false,
						},
						{
							title: 'Turkish Translation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation/turkish-translation',
							internal: false,
						},
					],
				},
				{
					title: 'Creative Writing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing',
							internal: false,
						},
						{
							title: 'Dramatic Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/dramatic-writing',
							internal: false,
						},
						{
							title: 'Short Fiction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/short-fiction',
							internal: false,
						},
						{
							title: 'Fantasy Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/fantasy-writing',
							internal: false,
						},
						{
							title: 'Novels',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/novels',
							internal: false,
						},
						{
							title: 'Novellas',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/novellas',
							internal: false,
						},
						{
							title: 'Science Fiction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/science-fiction',
							internal: false,
						},
						{
							title: 'Vignettes',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/vignettes',
							internal: false,
						},
						{
							title: 'Romance / Relationships',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/romance-relationship-writing',
							internal: false,
						},
						{
							title: 'Movie / TV Scripts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/movie-television-scripts',
							internal: false,
						},
						{
							title: 'Plays',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/plays',
							internal: false,
						},
						{
							title: 'Crime Fiction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/crime-fiction',
							internal: false,
						},
						{
							title: 'Personal Essays',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/personal-essays',
							internal: false,
						},
						{
							title: 'Journals / Diaries',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/journals-diaries',
							internal: false,
						},
						{
							title: 'Songs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/songs',
							internal: false,
						},
						{
							title: 'Speeches',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/speeches',
							internal: false,
						},
						{
							title: 'Memoirs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/memoirs',
							internal: false,
						},
						{
							title: 'Travel Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/travel-writing',
							internal: false,
						},
						{
							title: 'Letters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/letters',
							internal: false,
						},
						{
							title: 'Creative Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing/creative-marketing',
							internal: false,
						},
					],
				},
				{
					title: 'Book Writing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing',
							internal: false,
						},
						{
							title: 'Hire Ghostwriters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/ghostwriting-books',
							internal: false,
						},
						{
							title: 'Fiction Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/fiction-writing',
							internal: false,
						},
						{
							title: 'Short Stories',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/short-stories',
							internal: false,
						},
						{
							title: 'Romance Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/romance-writing',
							internal: false,
						},
						{
							title: 'Action / Adventure',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/action-adventure',
							internal: false,
						},
						{
							title: 'Women\'s Fiction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/women-fiction',
							internal: false,
						},
						{
							title: 'Horror',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/horror-writing',
							internal: false,
						},
						{
							title: 'LGBTQ+',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/LGBTQ',
							internal: false,
						},
						{
							title: 'Non-fiction Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/non-fiction-writing',
							internal: false,
						},
						{
							title: 'Suspense / Thriller',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/suspense-thriller',
							internal: false,
						},
						{
							title: 'Comic / Graphic Novel',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/comic-graphic-novel',
							internal: false,
						},
						{
							title: 'Science Fiction / Fantasy Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/science-fiction-writing',
							internal: false,
						},
						{
							title: 'Autobiography / Biography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/autobiography-biography',
							internal: false,
						},
						{
							title: 'Historial Fiction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/historical-fiction',
							internal: false,
						},
						{
							title: 'Contemporary Literature',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/contemporary-literature',
							internal: false,
						},
						{
							title: 'Contemporary Fiction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/contemporary-fiction',
							internal: false,
						},
						{
							title: 'Lit Fic',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/literary-fiction',
							internal: false,
						},
						{
							title: 'Detective / Mystery',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/detective-mystery',
							internal: false,
						},
						
						{
							title: 'Food / Drink / Cookbooks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/food-drink-cookbooks',
							internal: false,
						},
						{
							title: 'Science / Technology',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/science-technology',
							internal: false,
						},
						{
							title: 'Religion / Spirituality',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/religion-spirituality-writing',
							internal: false,
						},
						{
							title: 'Self-Help',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/Self-help',
							internal: false,
						},
						{
							title: 'Art / Photography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/art-photography',
							internal: false,
						},
						{
							title: 'Humanities / Scoial Sciences',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/humanities-social-sciences',
							internal: false,
						},
						{
							title: 'True Crime',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/true-crime',
							internal: false,
						},
						{
							title: 'Parenting / Families',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/parenting-families',
							internal: false,
						},
						{
							title: 'Guide / How-To',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/guide-how-to',
							internal: false,
						},
						{
							title: 'Essays',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/essays',
							internal: false,
						},
						{
							title: 'Magical Realism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/magical-realism',
							internal: false,
						},
						{
							title: 'Dystopian',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/dystopian',
							internal: false,
						},
						{
							title: 'History',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/History',
							internal: false,
						},
						{
							title: 'Travel',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing/Travel',
							internal: false,
						},
					],
				},
				{
					title: 'General Writing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing',
							internal: false,
						},
						{
							title: 'Technical Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/technical-writing',
							internal: false,
						},
						{
							title: 'Descriptive Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/descriptive-writing',
							internal: false,
						},
						{
							title: 'Letter Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/letter-writing',
							internal: false,
						},
						{
							title: 'Persuasive Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/persuasive-writing',
							internal: false,
						},
						{
							title: 'Ghost Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/ghost-writing',
							internal: false,
						},
						{
							title: 'Expository Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/expository-writing',
							internal: false,
						},
						{
							title: 'Narrative Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/narrative-writing',
							internal: false,
						},
						{
							title: 'Linguistics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/linguistics',
							internal: false,
						},
						{
							title: 'Blurb Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/blurb-writing',
							internal: false,
						},
						{
							title: 'Screenplays / Screen Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/screenplay-screen-writing',
							internal: false,
						},
						{
							title: 'Synopsis Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/synopsis-writing',
							internal: false,
						},
						{
							title: 'Summarizing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/summarizing',
							internal: false,
						},
						{
							title: 'Prose',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/prose',
							internal: false,
						},
						{
							title: 'Query Letters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/query-letters',
							internal: false,
						},
						{
							title: 'Satire',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/satire',
							internal: false,
						},
						{
							title: 'Redaction',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing/redaction',
							internal: false,
						},
					],
				},
				{
					title: 'Copywriting',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting',
							internal: false,
						},
						{
							title: 'SEO Copywriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/seo-copywriting',
							internal: false,
						},
						{
							title: 'Content Copywriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/content-copywriting',
							internal: false,
						},
						{
							title: 'Slogan Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/slogan-writing',
							internal: false,
						},
						{
							title: 'Creative Copywriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/creative-copywriting',
							internal: false,
						},
						{
							title: 'Technical Copywriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/technical-copywriting',
							internal: false,
						},
						{
							title: 'Social Media Copywriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/social-media-copywriting',
							internal: false,
						},
						{
							title: 'Marketing Copywriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/marketing-copywriting',
							internal: false,
						},
						{
							title: 'Product Descriptions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting/product-descriptions',
							internal: false,
						},
					],
				},
				{
					title: 'Editing / Proofreading',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading',
							internal: false,
						},
						{
							title: 'Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/editing',
							internal: false,
						},
						{
							title: 'Proofreading',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/proofreading',
							internal: false,
						},
						{
							title: 'English Grammar',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/english-grammar',
							internal: false,
						},
						{
							title: 'Index Creation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/index-creation',
							internal: false,
						},
						{
							title: 'Article Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/article-editing',
							internal: false,
						},
						{
							title: 'APA Formatting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/apa-style-editing-formatting',
							internal: false,
						},
						{
							title: 'Book Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/book-editing',
							internal: false,
						},
						{
							title: 'Copy Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/copy-editing',
							internal: false,
						},
						{
							title: 'Line Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/line-editing',
							internal: false,
						},
						{
							title: 'Chicago Manual of Style',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/chicago-manual-of-style',
							internal: false,
						},
						{
							title: 'Substantive Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/substantive-editing',
							internal: false,
						},
						{
							title: 'Associated Press (AP) Style',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/associated-press-ap-style',
							internal: false,
						},
						{
							title: 'Technical Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/technical-editing',
							internal: false,
						},
						{
							title: 'Academic Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/academic-editing',
							internal: false,
						},
						{
							title: 'Style Guide Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/style-guide-development',
							internal: false,
						},
						{
							title: 'Rewriting and Revisions',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/rewriting-and-revisions',
							internal: false,
						},
						{
							title: 'Editorial Consulting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/editorial-consulting',
							internal: false,
						},
						{
							title: 'Developmental Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/developmental-editing',
							internal: false,
						},
						{
							title: 'MLA Style',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/mla-style',
							internal: false,
						},
						{
							title: 'Citations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading/citations',
							internal: false,
						},
					],
				},
				{
					title: 'Web Content',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content',
							internal: false,
						},
						{
							title: 'General Web Content',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content/general-web-content',
							internal: false,
						},
						{
							title: 'Content Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content/content-writing',
							internal: false,
						},
						{
							title: 'Blog Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content/blog-writing',
							internal: false,
						},
						{
							title: 'On Page SEO',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content/on-page-seo',
							internal: false,
						},
						{
							title: 'Content Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content/content-editing',
							internal: false,
						},
						{
							title: 'Content Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content/content-marketing',
							internal: false,
						},
					],
				},
				{
					title: 'Article / News Writing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing',
							internal: false,
						},
						{
							title: 'Article Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/article-writing',
							internal: false,
						},
						{
							title: 'Blog Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/blog-writing',
							internal: false,
						},
						{
							title: 'Article Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/article-editing',
							internal: false,
						},
						{
							title: 'News Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/news-writing',
							internal: false,
						},
						{
							title: 'Journalism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/journalism',
							internal: false,
						},
						{
							title: 'Journalistic Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/journalistic-writing',
							internal: false,
						},
						{
							title: 'Investigative Reporting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/investigative-reporting',
							internal: false,
						},
						{
							title: 'Feature Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/feature-writing',
							internal: false,
						},
						{
							title: 'Editorial Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/editorial-writing',
							internal: false,
						},
						{
							title: 'Magazine Articles',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/magazine-articles',
							internal: false,
						},
						{
							title: 'Newspaper',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/newspaper',
							internal: false,
						},
						{
							title: 'Newsletters',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/newsletters',
							internal: false,
						},
						{
							title: 'Citations',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/citations',
							internal: false,
						},
						{
							title: 'Lifestyle Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/lifestyle-writing',
							internal: false,
						},
						{
							title: 'Fact Checking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/fact-checking',
							internal: false,
						},
						{
							title: 'Video Journalism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/video-journalism',
							internal: false,
						},
						{
							title: 'Business Journalism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/business-journalism',
							internal: false,
						},
						{
							title: 'Arts Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/arts-writing',
							internal: false,
						},
						{
							title: 'Corporate Blogging',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/corporate-blogging',
							internal: false,
						},
						{
							title: 'Content Curation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/content-curation',
							internal: false,
						},
						{
							title: 'How To Articles',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing/how-to-articles',
							internal: false,
						},
					],
				},
				{
					title: 'Subtitling',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/subtitling',
							internal: false,
						},
						{
							title: 'Intralingual Subtitling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/subtitling/intralingual-subtitling',
							internal: false,
						},
						{
							title: 'Interlingual Subtitling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/subtitling/interlingual-subtitling',
							internal: false,
						},
						{
							title: 'Closed Captioning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/subtitling/closed-captioning',
							internal: false,
						},
					],
				},
				{
					title: 'Researchers',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research',
							internal: false,
						},
						{
							title: 'Beta Reading',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research/beta-reading',
							internal: false,
						},
						{
							title: 'Essay Research',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research/essay-research',
							internal: false,
						},
						{
							title: 'Science Research',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research/science-research',
							internal: false,
						},
						{
							title: 'Report Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research/report-writing',
							internal: false,
						},
						{
							title: 'White Paper',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research/white-paper',
							internal: false,
						},
						{
							title: 'Fact Checking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research/fact-checking',
							internal: false,
						},
					],
				},
				{
					title: 'Writing for Industries',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries',
							internal: false,
						},
						{
							title: 'Marketing / Sales / PR',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/marketing-sales-pr',
							internal: false,
						},
						{
							title: 'Writing for Social Media',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/social-media-writing',
							internal: false,
						},
						{
							title: 'Health / Medical / Dental',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/health-medical-dental',
							internal: false,
						},
						{
							title: 'Psychology / Behavior',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/psychology-behavior',
							internal: false,
						},
						{
							title: 'Travel / Tourism / Entertainment',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/travel-tourism-entertainment',
							internal: false,
						},
						{
							title: 'Business / Finance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/business-finance',
							internal: false,
						},
						{
							title: 'Science / Math',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/science-math',
							internal: false,
						},
						{
							title: 'Sports / Fitness',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/sports-fitness',
							internal: false,
						},
						{
							title: 'Religion / Spirituality',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/religion-spirituality',
							internal: false,
						},
						{
							title: 'Government / Politics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/government-politics',
							internal: false,
						},
						{
							title: 'History / Culture',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/history-culture',
							internal: false,
						},
						{
							title: 'Food / Restaurants',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/food-restaurants',
							internal: false,
						},
						{
							title: 'Real Estate',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries/real-estate',
							internal: false,
						},
					],
				},
				{
					title: 'Scripts / Speeches / Storyboards',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards',
							internal: false,
						},
						{
							title: 'Storyboarding',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards/storyboarding',
							internal: false,
						},
						{
							title: 'Speech Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards/speech-writing',
							internal: false,
						},
						{
							title: 'Playwriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards/playwriting-dramaturgy',
							internal: false,
						},
						{
							title: 'Video Game Scripts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards/video-game-scripts',
							internal: false,
						},
						{
							title: 'Documentary',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards/documentary',
							internal: false,
						},
					],
				},
				{
					title: 'Jobs / Résumés',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes',
							internal: false,
						},
						{
							title: 'Résumé Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes/resume-writing',
							internal: false,
						},
						{
							title: 'Cover Letter Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes/cover-letter-writing',
							internal: false,
						},
						{
							title: 'Application Essay',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes/application-essay',
							internal: false,
						},
						{
							title: 'Job Description Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes/job-description-writing',
							internal: false,
						},
						{
							title: 'Curriculum Vitae (CV)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes/curriculum-vitae-cv-writing',
							internal: false,
						},
						{
							title: 'LinkedIn Profile',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes/linkedin-profile',
							internal: false,
						},
					],
				},
				{
					title: 'Review Writing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing',
							internal: false,
						},
						{
							title: 'Review Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/review-writing',
							internal: false,
						},
						{
							title: 'Document Reviews',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/document-review',
							internal: false,
						},
						{
							title: 'Book Reviews',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/book-reviews',
							internal: false,
						},
						{
							title: 'Product Reviews',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/product-reviews',
							internal: false,
						},
						{
							title: 'Literary Criticism',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/literary-criticism',
							internal: false,
						},
						{
							title: 'Music Reviews',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/music-review',
							internal: false,
						},
						{
							title: 'Art Reviews',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/art-reviews',
							internal: false,
						},
						{
							title: 'Movie Reviews',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing/movie-review',
							internal: false,
						},
					],
				},
				{
					title: 'Christian Worship',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship',
							internal: false,
						},
						{
							title: 'Christian Articles / Blogs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship/christian-articles-blogs',
							internal: false,
						},
						{
							title: 'Christian Books / eBooks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship/christian-books-ebooks',
							internal: false,
						},
						{
							title: 'Christian Ghostwriting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship/christian-ghostwriting',
							internal: false,
						},
						{
							title: 'Christian Book Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship/christian-book-editing',
							internal: false,
						},
					],
				},
				{
					title: 'Grants / Pitches',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals',
							internal: false,
						},
						{
							title: 'Investment Pitches',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals/investment-sales-business-pitches',
							internal: false,
						},
						{
							title: 'Proposal Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals/proposal-writing',
							internal: false,
						},
						{
							title: 'Crowdfunding Pitches',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals/business-proposal-writing',
							internal: false,
						},
						{
							title: 'Grant Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals/grant-writing',
							internal: false,
						},
						{
							title: 'Government Proposals',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals/government-proposals',
							internal: false,
						},
					],
				},
				{
					title: 'Songs / Poems',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/songs-poems',
							internal: false,
						},
						{
							title: 'Poetry',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/songs-poems/poetry',
							internal: false,
						},
						{
							title: 'Lyrics Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/songs-poems/lyrics-writing',
							internal: false,
						},
						{
							title: 'Song Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/songs-poems/song-writing',
							internal: false,
						},
					],
				},
			],
		},
		{
			title: 'Business / Admin',
			navs: [
				{
					title: 'View All',
					strong: true,
					link:
						'https://www.hostjane.com/marketplace/categories/business-admin-support',
					internal: false,
				},
				{
					title: 'Digital Marketing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing',
							internal: false,
						},
						{
							title: 'Search Engine Optimization (SEO)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/search-engine-optimization-seo',
							internal: false,
						},
						{
							title: 'Social Media Marketing (SMM)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/social-media-marketing-smm',
							internal: false,
						},
						{
							title: 'Blog Advertising',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/blog-advertising',
							internal: false,
						},
						{
							title: 'Mobile Marketing / Advertising',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/mobile-marketing-advertising',
							internal: false,
						},
						{
							title: 'Email Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/email-text',
							internal: false,
						},
						{
							title: 'Digital Content Leads',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/digital-communication',
							internal: false,
						},
						{
							title: 'SEM / PPC',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/search-engine-marketing-pay-per-click-advertising',
							internal: false,
						},
						{
							title: 'SEM / PPC',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/search-engine-marketing-pay-per-click-advertising',
							internal: false,
						},
						{
							title: 'Guest Posting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/guest-posting',
							internal: false,
						},
						{
							title: 'Site Performance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/site-performance',
							internal: false,
						},
						{
							title: 'Landing Pages',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/landing-pages',
							internal: false,
						},
						{
							title: 'Influencer Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/influencer-marketing',
							internal: false,
						},
						{
							title: 'SMS / Text Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/sms-text-marketing',
							internal: false,
						},
						{
							title: 'Marketing Strategists',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/marketing-strategy',
							internal: false,
						},
						{
							title: 'Survey Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/surveys',
							internal: false,
						},
						{
							title: 'Web Traffic',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/web-traffic',
							internal: false,
						},
						{
							title: 'Local SEO / Listings',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/local-listings',
							internal: false,
						},
						{
							title: 'Crowdfunding',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/crowdfunding',
							internal: false,
						},
						{
							title: 'Domain Research',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/domain-research',
							internal: false,
						},
						{
							title: 'Web Analytics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/web-analytics',
							internal: false,
						},
						{
							title: 'Other Web Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing/general-other-web-marketing',
							internal: false,
						},
					],
				},
				{
					title: 'Accounting / Finance / Tax',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax',
							internal: false,
						},
						{
							title: 'Accounting / Tax Returns',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/accounting-taxes',
							internal: false,
						},
						{
							title: 'Virtual / Cryptocurrency',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/virtual-cryptocurrency',
							internal: false,
						},
						{
							title: 'Bookkeeping',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/bookkeeping',
							internal: false,
						},
						{
							title: 'Payroll',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/payroll',
							internal: false,
						},
						{
							title: 'Profit / Loss',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/profit-loss',
							internal: false,
						},
						{
							title: 'Accounts Payable / Receivable',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/accounts-payable-receivable',
							internal: false,
						},
						{
							title: 'Financial Analysis / Planning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/financial-analysis-planning',
							internal: false,
						},
						{
							title: 'Invoicing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/invoicing',
							internal: false,
						},
						{
							title: 'Forex Trading',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/forex-trading',
							internal: false,
						},
						{
							title: 'Cost Accounting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/cost-accounting',
							internal: false,
						},
						{
							title: 'Investment Banking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/investment-banking',
							internal: false,
						},
						{
							title: 'Sage',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/sage',
							internal: false,
						},
						{
							title: 'NetSuite',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/netsuite',
							internal: false,
						},
						{
							title: 'Xero',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/xero',
							internal: false,
						},
						{
							title: 'QuickBooks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/quickbooks',
							internal: false,
						},
						{
							title: 'TurboTax',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/turbotax',
							internal: false,
						},
						{
							title: 'Stock Trading',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/stock-trading',
							internal: false,
						},
						{
							title: 'Personal Finances',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/personal-finances',
							internal: false,
						},
						{
							title: 'Banking / Loans',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/banking-loans',
							internal: false,
						},
						{
							title: 'Insurance / Risk / Claims',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/risk-claims',
							internal: false,
						},
						{
							title: 'Debt Collections',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/collections',
							internal: false,
						},
						{
							title: 'Financial Audits',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/financial-audits',
							internal: false,
						},
						{
							title: 'Securities',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/securities',
							internal: false,
						},
						{
							title: 'FreshBooks',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/freshbooks',
							internal: false,
						},
						{
							title: 'Oracle Financials',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax/oracle-financials',
							internal: false,
						},
					],
				},
				{
					title: 'Business Help / Consulting',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting',
							internal: false,
						},
						{
							title: 'Start-ups / Small Business Help',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/start-ups-small-business',
							internal: false,
						},
						{
							title: 'Project Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/project-management',
							internal: false,
						},
						{
							title: 'Business Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/business-development',
							internal: false,
						},
						{
							title: 'Planning / Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/planning-development',
							internal: false,
						},
						{
							title: 'Management / Leadership',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/management-leadership',
							internal: false,
						},
						{
							title: 'Strategy / Competitor Analysis',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/strategy-competitor-analysis',
							internal: false,
						},
						{
							title: 'Business Communications',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/business-communications',
							internal: false,
						},
						{
							title: 'Business Valuation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting/business-valuation',
							internal: false,
						},
					],
				},
				{
					title: 'Legal Assistance',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance',
							internal: false,
						},
						{
							title: 'IP / Copyright Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/intellectual-property-copyright-law',
							internal: false,
						},
						{
							title: 'Employment Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/employment-law',
							internal: false,
						},
						{
							title: 'Contracts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/contracts',
							internal: false,
						},
						{
							title: 'Legal Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/legal-writing',
							internal: false,
						},
						{
							title: 'Civil Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/civil-law',
							internal: false,
						},
						{
							title: 'Civil Litigation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/civil-litigation',
							internal: false,
						},
						{
							title: 'Legal Research',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/legal-research',
							internal: false,
						},
						{
							title: 'Banking Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/banking-law',
							internal: false,
						},
						{
							title: 'Legal Documents',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/legal-documents',
							internal: false,
						},
						{
							title: 'Tax Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/tax-law',
							internal: false,
						},
						{
							title: 'Paralegal',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/paralegal',
							internal: false,
						},
						{
							title: 'Insurance Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/insurance',
							internal: false,
						},
						{
							title: 'Personal Injury Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/personal-injury-law',
							internal: false,
						},
						{
							title: 'Legal Transcription',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/legal-transcription',
							internal: false,
						},
						{
							title: 'Employment Contracts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/employment-contracts',
							internal: false,
						},
						{
							title: 'Business Formation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/business-formation',
							internal: false,
						},
						{
							title: 'Bankruptcy',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/bankruptcy',
							internal: false,
						},
						{
							title: 'Securities Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/securities-law',
							internal: false,
						},
						{
							title: 'Real Estate Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/real-estate-law',
							internal: false,
						},
						{
							title: 'Immigration Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/immigration-law',
							internal: false,
						},
						{
							title: 'Criminal Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/criminal-law',
							internal: false,
						},
						{
							title: 'Corporate Law',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/corporate-law',
							internal: false,
						},
						{
							title: 'Mediation / Negotiation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/mediation-negotiation',
							internal: false,
						},
						{
							title: 'Patents',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/patents',
							internal: false,
						},
						{
							title: 'Trademark Consulting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/trademark-consulting',
							internal: false,
						},
						{
							title: 'Licensing / Franchising',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance/licensing',
							internal: false,
						},
					],
				},
				{
					title: 'Personal / Virtual Assistants',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants',
							internal: false,
						},
						{
							title: 'Business Help / Consulting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/business-consulting',
							internal: false,
						},
						{
							title: 'Virtual Assistants',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/virtual-assistants',
							internal: false,
						},
						{
							title: 'Remote Office Workers',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/remote-office-workers',
							internal: false,
						},
						{
							title: 'Personal Assistant',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/personal-assistant',
							internal: false,
						},
						{
							title: 'Chat Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/chat-support',
							internal: false,
						},
						{
							title: 'Email Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/email-support',
							internal: false,
						},
						{
							title: 'Appointment Setting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/appointment-setting',
							internal: false,
						},
						{
							title: 'Records Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/records-management',
							internal: false,
						},
						{
							title: 'Web Research / Posting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/web-research-posting',
							internal: false,
						},
						{
							title: 'Web Scraping',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/web-scraping',
							internal: false,
						},
						{
							title: 'Slideshow Design',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/slideshow-design',
							internal: false,
						},
						{
							title: 'Travel Planning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/travel-planning',
							internal: false,
						},
						{
							title: 'Event Planning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/event-planning',
							internal: false,
						},
						{
							title: 'Presentation Help',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/presentation-help',
							internal: false,
						},
						{
							title: 'Microsoft PowerPoint',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/microsoft-powerpoint',
							internal: false,
						},
						{
							title: 'Scheduling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/scheduling',
							internal: false,
						},
						{
							title: 'Calendar Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants/calendar-management',
							internal: false,
						},
					],
				},
				{
					title: 'Transcription Services',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services',
							internal: false,
						},
						{
							title: 'Audio Transcription',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/audio-transcription',
							internal: false,
						},
						{
							title: 'Medical Transcription',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/medical-transcription',
							internal: false,
						},
						{
							title: 'Legal Transcription',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/legal-transcription',
							internal: false,
						},
						{
							title: 'Video Transcription',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/video-transcription',
							internal: false,
						},
						{
							title: 'Shorthand / Stenography',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/shorthand-stenography',
							internal: false,
						},
						{
							title: 'Express Scribe',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/express-scribe',
							internal: false,
						},
						{
							title: 'Dictation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/dictation',
							internal: false,
						},
						{
							title: 'Court Reporting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services/court-reporting',
							internal: false,
						},
					],
				},
				{
					title: 'Sales / General Marketing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing',
							internal: false,
						},
						{
							title: 'Google Analytics',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/google-analytics',
							internal: false,
						},
						{
							title: 'Advertising',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/advertising',
							internal: false,
						},
						{
							title: 'Direct Marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/direct-marketing',
							internal: false,
						},
						{
							title: 'CRM',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/crm',
							internal: false,
						},
						{
							title: 'Sales',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/sales',
							internal: false,
						},
						{
							title: 'Lead Generation',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/lead-generation',
							internal: false,
						},
						{
							title: 'Public Relations (PR)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/public-relations-pr',
							internal: false,
						},
						{
							title: 'Inbound marketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing/inbound-marketing',
							internal: false,
						},
					],
				},
				{
					title: 'Business Intelligence',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence',
							internal: false,
						},
						{
							title: 'Data Analysis',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/business-data-analysis',
							internal: false,
						},
						{
							title: 'Data Warehousing / Laking',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/data-warehousing-laking',
							internal: false,
						},
						{
							title: 'Data Visualization / Graphs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/data-visualization-graphs',
							internal: false,
						},
						{
							title: 'Data Integration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/data-integration',
							internal: false,
						},
						{
							title: 'Data Architecture',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/data-architecture',
							internal: false,
						},
						{
							title: 'Data Governance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/data-governance',
							internal: false,
						},
						{
							title: 'Business Performance Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/business-performance-management',
							internal: false,
						},
						{
							title: 'Risk Assessment',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence/risk-assessment',
							internal: false,
						},
					],
				},
				{
					title: 'Telemarketing / Remote Selling',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing',
							internal: false,
						},
						{
							title: 'Telemarketing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing/telemarketing',
							internal: false,
						},
						{
							title: 'Direct Selling (B2C)',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing/business-to-consumer-sales',
							internal: false,
						},
						{
							title: 'B2B Sales',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing/outbound-sales',
							internal: false,
						},
						{
							title: 'Phone Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing/phone-support',
							internal: false,
						},
						{
							title: 'Cold Calling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing/cold-calling',
							internal: false,
						},
						{
							title: 'Call Center Agents',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing/call-center-management',
							internal: false,
						},
					],
				},
				{
					title: 'Press Release Writing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing',
							internal: false,
						},
						{
							title: 'Launch Release Writing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/launch-release-writing',
							internal: false,
						},
						{
							title: 'Product Press Releases',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/product-press-release',
							internal: false,
						},
						{
							title: 'General News Releases',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/general-news-releases',
							internal: false,
						},
						{
							title: 'Event Press Releases',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/event-press-releases',
							internal: false,
						},
						{
							title: 'Employment / Staff',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/employment-staff-releases',
							internal: false,
						},
						{
							title: 'Expert Position Releases',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/expert-position-releases',
							internal: false,
						},
						{
							title: 'Award Press Releases',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing/award-press-release',
							internal: false,
						},
					],
				},
				
				{
					title: 'Data Entry / Micro Jobs',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry',
							internal: false,
						},
						{
							title: 'Manual Data Entry',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/manual-data-entry',
							internal: false,
						},
						{
							title: 'Data Mining',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-mining',
							internal: false,
						},
						{
							title: 'Data Analysis',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-analysis',
							internal: false,
						},
						{
							title: 'Copy and Paste',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/copy-and-paste',
							internal: false,
						},
						{
							title: 'Data Collection',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-collection',
							internal: false,
						},
						{
							title: 'Data Processing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-processing',
							internal: false,
						},
						{
							title: 'Data Audit',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-audit',
							internal: false,
						},
						{
							title: 'Data Cleaning',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-cleaning',
							internal: false,
						},
						{
							title: 'Records Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/records-management',
							internal: false,
						},
						{
							title: 'Order Entry',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/order-entry',
							internal: false,
						},
						{
							title: 'Document Conversion',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/document-conversion',
							internal: false,
						},
						{
							title: 'Order Processing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/order-processing',
							internal: false,
						},
						{
							title: 'eBay Sales',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/ebay-sales',
							internal: false,
						},
						{
							title: 'Form Filling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/form-filling',
							internal: false,
						},
						{
							title: 'Data Encoding',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-encoding',
							internal: false,
						},
						{
							title: 'Data Migration / Conversion',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/data-conversion',
							internal: false,
						},
						{
							title: 'File Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry/file-management',
							internal: false,
						},
					],
				},
				{
					title: 'Spreadsheets / Excel / Office',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office',
							internal: false,
						},
						{
							title: 'Microsoft Excel Wizards',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-excel',
							internal: false,
						},
						{
							title: 'Google Sheet Specialists',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/google-sheets',
							internal: false,
						},
						{
							title: 'Microsoft Word Pros',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-word',
							internal: false,
						},
						
						{
							title: 'Excel PivotTables',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-excel-pivot-tables',
							internal: false,
						},
						{
							title: 'Microsoft SharePoint',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-sharepoint',
							internal: false,
						},
						{
							title: 'Excel Modeling',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-excel-models',
							internal: false,
						},
						{
							title: 'Excel Dashboards',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-excel-dashboards',
							internal: false,
						},
						{
							title: 'Excel Charts',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-excel-charts',
							internal: false,
						},
						{
							title: 'Microsoft 365',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-365',
							internal: false,
						},
						{
							title: 'Microsoft PowerPoint',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-powerpoint',
							internal: false,
						},
						{
							title: 'Microsoft Visio',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-visio',
							internal: false,
						},
						{
							title: 'Microsoft Publisher',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-publisher',
							internal: false,
						},
						{
							title: 'Microsoft Project',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-project',
							internal: false,
						},
						{
							title: 'Microsoft Access',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-access',
							internal: false,
						},
						{
							title: 'Microsoft Outlook',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office/microsoft-outlook',
							internal: false,
						},
					],
				},
				{
					title: 'Customer Service',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service',
							internal: false,
						},
						{
							title: 'Order Processing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service/order-processing',
							internal: false,
						},
						{
							title: 'Phone Support',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service/phone-support',
							internal: false,
						},
						{
							title: 'Account Management',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service/account-management',
							internal: false,
						},
						{
							title: 'Business Administration',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service/business-administration',
							internal: false,
						},
						{
							title: 'Client Issue Resolution',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service/client-issue-resolution',
							internal: false,
						},
					],
				},
				{
					title: 'Word Processing / Typing',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing',
							internal: false,
						},
						{
							title: 'Microsoft Word',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing/microsoft-word',
							internal: false,
						},
						{
							title: 'Reports',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing/reports',
							internal: false,
						},
						{
							title: 'Google Docs',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing/google-docs',
							internal: false,
						},
						{
							title: 'Fillable Forms',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing/fillable-forms',
							internal: false,
						},
						{
							title: 'PDF Editing',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing/pdf-editing',
							internal: false,
						},
						{
							title: 'Keyboarding',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing/keyboarding',
							internal: false,
						},
					],
				},
				{
					title: 'Mailings / Lists',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/mailings-lists',
							internal: false,
						},
						{
							title: 'Bulk Mailings / Lists',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/mailings-lists/bulk-mailings-lists',
							internal: false,
						},
						{
							title: 'Mail Merge',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/mailings-lists/mail-merge',
							internal: false,
						},
						{
							title: 'Email List Building',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/mailings-lists/email-list-building',
							internal: false,
						},
					],
				},
				{
					title: 'Human Resources (HR)',
					navs: [
						{
							title: 'View All',
							strong: true,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr',
							internal: false,
						},
						{
							title: 'General HR',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr/general-hr',
							internal: false,
						},
						{
							title: 'Training / Development',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr/training-development',
							internal: false,
						},
						
						{
							title: 'Staffing / Recruiting',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr/staffing-recruiting',
							internal: false,
						},
						{
							title: 'Benefits / Insurance',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr/benefits-insurance',
							internal: false,
						},
						{
							title: 'Conflict Resolution',
							strong: false,
							link:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr/conflict-resolution',
							internal: false,
						},
					],
				},
			],
		},
		{
			title: 'VPS & Cloud Hosting',
			navs: [
				{
					title: 'Hosting Homepage',
					strong: false,
					link: 'https://cloud.hostjane.com',
					internal: false,
				},
				{
					title: 'VPS for Business',
					strong: false,
					link: 'https://cloud.hostjane.com/vps-hosting',
					internal: false,
				},
				{
					title: 'WordPress Hosting',
					strong: false,
					link: 'https://cloud.hostjane.com/wordpress/',
					internal: false,
				},
				{
					title: 'Cloud Hosting',
					strong: false,
					link: 'https://cloud.hostjane.com/cloud/',
					internal: false,
				},
				{
					title: 'Windows Hosting',
					strong: false,
					link: 'https://cloud.hostjane.com/windows/',
					internal: false,
				},
				{
					title: 'Contact Support',
					strong: false,
					link: '/marketplace/support-ticket',
					internal: false,
				},
				{
					title: 'Manage Billing',
					strong: false,
					link: 'https://cloud.hostjane.com/hosting/login',
					internal: false,
				},
			],
		},
		{
			title: 'Become a Seller',
			link: 'https://www.hostjane.com/sell/',
			internal: false,
			type: 'hasBorderTop',
			icon: SellIcon,
		},
		{
			title: 'Browse Skills',
			link: 'https://www.hostjane.com/marketplace/skills',
			internal: false,
			icon: SkillsIcon,
		},
		{
			title: 'Jane\'s on a Mission',
			link: 'https://www.hostjane.com/about/',
			internal: true,
			icon: MissionIcon,
		},
		{
			title: 'Create account',
			link: 'https://www.hostjane.com/marketplace/register',
			internal: true,
			type: 'footerButton',
		},
	],
};
